import api from "../api.service"

const queryString = require("query-string")

/**
 * Creates a new subscription, if one doesn't exist already.
 * If a customer does not exist, it will be created.
 * https://stripe.com/docs/billing/subscriptions/creating
 *
 * @param orgId {String}
 * @param payload {Object}
 * @param [payload.customer] {Object} View 'canWriteCustomerFields' from stripe.rules.js.
 * @param [payload.subscription] {Object} For supported payload: https://stripe.com/docs/api/subscriptions/create
 * @param [payload.promo] {String} A promotion code to apply, e.g. WELCOME50
 * @param [payload.oobCode] {String} The AppSumo "oobCode" from the activation redirect URI query param.
 * @param [options] {Object} Query options
 * @param [options.idempotency_key] {String} Unique idempotency key to be used with Stripe request.
 * @param [options.isTrial] {Boolean} If true, a Stripe trial will be setup. Only when no "oobCode" has been provided.
 * @return {Promise<*>}
 */
export function createSubscription(orgId, payload, options = {}) {
    const qs = queryString.stringify(options);
    const url = payload?.oobCode ? `/org/${orgId}/subscription/appsumo?${qs}` : `/org/${orgId}/subscription?${qs}`;

    return api
        .post(url, payload)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Creates a new subscription, if one doesn't exist already.
 * If a customer does not exist, it will be created.
 * https://stripe.com/docs/billing/subscriptions/creating
 *
 * @param orgId {String}
 * @param oobCode {String} The AppSumo "oobCode" from the activation redirect URI query param.
 * @param payload {Object}
 * @param [payload.customer] {Object} View 'canWriteCustomerFields' from stripe.rules.js.
 * @param [options] {Object} Query options
 * @param [options.idempotency_key] {String} Unique idempotency key to be used with Stripe request.
 * @return {Promise<*>}
 */
export function createAppSumoSubscription(orgId, oobCode, payload, options = {}) {
    const qs = queryString.stringify(options)
    return api
        .post(`/org/${orgId}/subscription/appsumo?${qs}`, {
            ...(payload ?? {}),
            oobCode
        })
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export function getSubscription(orgId) {
    return api
        .get(`/org/${orgId}/subscription`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Cancels a customer’s subscription immediately. The customer will not be charged again for the subscription.
 * Note, however, that any pending invoice items that you’ve created will still be charged for at the end of the period.
 * https://stripe.com/docs/api/subscriptions/cancel
 *
 * @param orgId {String}
 * @return {Promise<*>}
 */
export function cancelSubscription(orgId) {
    return api
        .delete(`/org/${orgId}/subscription`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Creates a Stripe customer for the provided organization.
 * https://stripe.com/docs/api/customers/create
 *
 * @param orgId {String}
 * @param payload {Object} View 'canWriteCustomerFields' from stripe.rules.js.
 * @param [options] {Object} Query options
 * @param [options.idempotency_key] {String} Unique idempotency key to be used with Stripe request.
 * @return {Promise<*>}
 */
export function createCustomer(orgId, payload, options = {}) {
    const qs = queryString.stringify(options)
    return api
        .post(`/org/${orgId}/subscription/customer?${qs}`, payload)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Gets the Stripe customer information for the provided organization.
 * https://stripe.com/docs/api/customers/retrieve
 *
 * @param orgId {String}
 * @return {Promise<string>}
 */
export function getCustomer(orgId) {
    return api
        .get(`/org/${orgId}/subscription/customer`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Update Stripe customer information for the provided organization.
 * https://stripe.com/docs/api/customers/update
 *
 * @param orgId {String}
 * @param payload {Object} View 'canWriteCustomerFields' from stripe.rules.js
 * @return {Promise<*>}
 */
export function updateCustomer(orgId, payload) {
    return api
        .put(`/org/${orgId}/subscription/customer`, payload)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Set customer's default payment method.
 * https://stripe.com/docs/api/customers/update
 *
 * @param orgId {String}
 * @param paymentMethodId {String} Stripe payment method id
 * @return {Promise<void>}
 */
export function setCustomerDefaultPaymentMethod(orgId, paymentMethodId) {
    return api
        .put(`/org/${orgId}/subscription/customer/default-pm/${paymentMethodId}`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Permanently deletes a customer. It cannot be undone. Also immediately cancels any active subscriptions on the customer.
 * https://stripe.com/docs/api/customers/delete
 *
 * @param orgId {String}
 * @return {Promise<*>}
 */
export function deleteCustomer(orgId) {
    return api
        .delete(`/org/${orgId}/subscription/customer`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Returns the list of PaymentMethods for the organization's customer
 * https://stripe.com/docs/api/payment_methods/list
 *
 * @param orgId {String}
 * @return {Promise<*>}
 */
export function getPaymentMethodList(orgId) {
    return api
        .get(`/org/${orgId}/subscription/payment-method`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Update an organization's customer payment method
 * https://stripe.com/docs/api/payment_methods/update
 *
 * @param orgId {String}
 * @param paymentMethodId {String} Stripe payment method id
 * @param payload {Object} Update data
 * @return {Promise<*>}
 */
// export function updatePaymentMethod(orgId, paymentMethodId, payload) {
//     return api.put(`/org/${orgId}/subscription/payment-method/${paymentMethodId}`, payload)
//         .then(res => res.data)
//         .catch(err => {
//             throw api.getResponseError(err)
//         })
// }

/**
 * Attaches PaymentMethods for the organization's customer
 * https://stripe.com/docs/api/payment_methods/attach
 *
 * @param orgId {String}
 * @param paymentMethodId {String} Stripe payment method id
 * @param [options] {Object}
 * @param [options.detachDefault] {Boolean} If true, the current default payment method will get detached
 * @param [options.setDefault] {Boolean} If true, will set as customer's default payment method
 * @return {Promise<*>}
 */
export function attachPaymentMethod(orgId, paymentMethodId, options = {}) {
    const qs = queryString.stringify(options)
    return api
        .post(`/org/${orgId}/subscription/payment-method/${paymentMethodId}/attach?${qs}`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Detaches PaymentMethods for the organization's customer
 * https://stripe.com/docs/api/payment_methods/detach
 *
 * @param orgId {String}
 * @param paymentMethodId {String} Stripe payment method id
 * @return {Promise<*>}
 */
export function detachPaymentMethod(orgId, paymentMethodId) {
    return api
        .delete(`/org/${orgId}/subscription/payment-method/${paymentMethodId}/detach`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * Retrieves an upcoming invoice
 * https://stripe.com/docs/api/invoices/upcoming
 *
 * @param orgId {String}
 * @return {Promise<*>}
 */
export function getUpcomingInvoice(orgId) {
    return api
        .get(`/org/${orgId}/subscription/invoice/upcoming`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * https://stripe.com/docs/api/accounts/create
 * @param orgId {string}
 * @param [data] {object} The optional parameters of the Stripe API payload. Type is not required, as it defaults to "standard" from the API
 * @param [options] {object}
 * @param [options.idempotencyKey] {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createAccount(orgId, data = {}, options = {}) {
    return api
        .post(`/org/${orgId}/stripe/connect/account`, {
            payload: data,
            ...options
        })
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * https://stripe.com/docs/api/accounts/retrieve
 * https://stripe.com/docs/connect/enable-payment-acceptance-guide?platform=web#handle-incomplete-onboarding
 *
 * @param orgId {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAccount(orgId) {
    return api
        .get(`/org/${orgId}/stripe/connect/account`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * @param orgId {string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeAccount(orgId) {
    return api
        .delete(`/org/${orgId}/stripe/connect/account`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * https://stripe.com/docs/api/account_links/create
 *
 * IMPORTANT
 *
 * After you create the account link, you won’t be able to read or write information for the account.
 * After you call this, the user will be redirected to Stripe.
 *
 * After the user completes the Stripe flow, they will return to one of our following URLs:
 *
 * 1.https://missionx.ai/home?from=stripe&orgId=[ORG_ID]&actionType=[TYPE]&actionValue=return_url
 * 2.https://missionx.ai/home?from=stripe&orgId=[ORG_ID]&actionType=[TYPE]&actionValue=refresh_url
 *
 * Basically, the "actionValue" param will be changing between the two.
 * Read more on their differences, in order to handle appropriately:
 * https://stripe.com/docs/connect/enable-payment-acceptance-guide?platform=web#handle-user-returning-to-platform
 *
 * @param orgId {string}
 * @param type {'account_onboarding'|'account_update'} The type of account link the user is requesting. See Stripe API for more details.
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createAccountLink(orgId, type) {
    return api
        .post(`/org/${orgId}/stripe/connect/account/link`, {type})
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}
