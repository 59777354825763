import React from "react"
import DropDown from "./DropDown"
import Tip from "./Tip"
import Button from "./Button"
import { TbLocationPlus } from "react-icons/tb"
import { formatMissionPeople, canUserInviteToMission } from "./MissionUtils"
import cx from "classnames"
import { getPersonName } from "../utils/utils"
import RoundAvatar from "./RoundAvatar"

import { getRefId, isArchived, getRole, getRolesText, isMissionComplete } from "../utils/utils"

import "./PersonAdder.scss"

class PersonAdder extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            person: "",
        }
    }
    onSelectHandler(person) {
        this.props.onAdd(person)
    }
    matchPerson(person, value) {
        if (!person) return

        let found = false

        let name = getPersonName(person)

        if (name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            found = true
        } else if (person.role) {
            try {
                found = person.role.toLowerCase().indexOf(value.toLowerCase()) !== -1
            } catch (err) {
                console.error(person)
            }
        }

        return found
    }
    render() {
        if (!this.props.people) {
            this.props.app.silentFail("msnjierhjkf-399893")

            return <></>
        }

        const allPeople = this.props.searchList ? formatMissionPeople(this.props.searchList) : this.props.org.people

        let eligiblePeople = []

        allPeople.forEach((p) => {
            if (!p || !p._id) return

            if (
                this.props.people.findIndex((ep) => {
                    if (!ep || !ep._id) return false
                    return ep._id === p._id
                }) === -1
            ) {
                eligiblePeople.push(p)
            }
        })

        const meInMission = this.props.mission?.people?.find((p) => getRefId(p) === this.props.app?.state.person._id)
        const meInOrg = this.props.org?.people?.find((p) => getRefId(p) === this.props.app.state.person._id)

        const canInvite = meInMission
            ? canUserInviteToMission(this.props.mission, meInMission, this.props.org, meInOrg)
            : false

        return (
            <div
                style={{ paddingTop: 20 }}
                className="dna-person-adder"
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onMouseDown={(e) => {
                    e.stopPropagation()
                }}
            >
                {this.props.adderMsg}

                {eligiblePeople.length > 0 && <label>Select a team member...</label>}

                {eligiblePeople.length === 0 && (
                    <>
                        <label>The entire eligible project team has already been added.</label>

                        {canInvite && this.props.startInvite && (
                            <>
                                <p>You can invite more people to your project.</p>
                                <Button
                                    icon
                                    onClick={() => {
                                        this.props.startInvite(true)
                                    }}
                                >
                                    <TbLocationPlus /> Invite people
                                </Button>
                            </>
                        )}
                    </>
                )}

                <div className="dna-center-flex">
                    {eligiblePeople.length > 0 && (
                        <>
                            <DropDown
                                className="w-400"
                                getItemValue={(item) => getRefId(item)}
                                value={this.state.person}
                                onChange={(val) => {
                                    this.setState({
                                        person: val,
                                    })
                                }}
                                items={eligiblePeople}
                                onSelect={(id) => {
                                    this.onSelectHandler(id)
                                }}
                                selectOnBlur={false}
                                open={true}
                                autoFocus={true}
                                inputProps={{ placeholder: "Find...", type: "text" }}
                                shouldItemRender={this.matchPerson}
                                template={(personDetails, value, isHighlighted) => {
                                    return (
                                        <span>
                                            <Template
                                                personDetails={personDetails}
                                                isHighlighted={isHighlighted}
                                                orgData={this.props.org}
                                                app={this.props.app}
                                                mission={this.props.mission}
                                                onSelectHandler={this.onSelectHandler.bind(this)}
                                            />
                                        </span>
                                    )
                                }}
                            />
                            {canInvite && this.props.startInvite && (
                                <Tip
                                    style={{ transform: "translateY(2px)", marginLeft: 1 }}
                                    title="Invite someone new to your project"
                                >
                                    <Button
                                        icon
                                        onClick={() => {
                                            this.props.startInvite(true)
                                        }}
                                    >
                                        <TbLocationPlus />
                                    </Button>
                                </Tip>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export const Template = ({ personDetails, isHighlighted, orgData, app, mission, onSelectHandler, comp }) => {
    const cn = cx("dna-dd-item dna-people-list-item dna-center-flex", {
        active: personDetails.isHighlighted,
        issue: personDetails.issue,
        "dna-dd-item active": isHighlighted,
    })

    const isMe = app?.state.person._id === getRefId(personDetails)
    const meInOrg = orgData?.people?.find((p) => getRefId(p) === getRefId(personDetails))

    const orgRole = meInOrg?.role ? orgData.roles?.find((p) => p._id === meInOrg?.role) : null
    const office = orgData?.offices?.find((o) => !isArchived(o) && o._id === meInOrg?.office)
    const myProjectRoles = mission?.planItems.filter((p) => !p.inactive && p.person === getRefId(personDetails)) || []
    const dep = orgData?.departments?.find((d) => !isArchived(d) && d._id === meInOrg?.departments[0])
    return (
        <div style={{ alignItems: "flex-start" }} key={getRefId(personDetails)} className={cn}>
            <RoundAvatar noTip person={meInOrg || personDetails} width={42} style={{ marginRight: 10, marginTop: 4 }} />{" "}
            <div>
                <strong>{!isMe ? <>{getPersonName(personDetails)}</> : "Me 👋"}</strong>
                {mission && myProjectRoles.length === 0 && (
                    <div className="dna-smaller-text" style={{ marginBottom: 5 }}>
                        No active project role
                    </div>
                )}
                {myProjectRoles.length > 0 && mission && orgData && meInOrg && (
                    <div className="dna-smaller-text" style={{ marginBottom: 5 }}>
                        Project role{myProjectRoles.length > 1 ? "s" : ""}:{" "}
                        {getRolesText({ person: meInOrg || app?.state.person, mission, orgData })}
                    </div>
                )}

                {orgData && <div className="dna-smaller-text">Organization: {getRole(orgRole, orgData).name}</div>}

                {((orgData?.departments && meInOrg?.departments?.length > 0) || office) && (
                    <div className="dna-smaller-text dna-center-flex wrap">
                        {dep?.title}
                        {dep && <>&nbsp;&nbsp;</>}
                        {office?.title}
                    </div>
                )}

                <div className="dna-smaller-text">{personDetails.msg}</div>

                {comp && comp}
            </div>
        </div>
    )
}

PersonAdder.displayName = "PersonAdder"
export default PersonAdder
