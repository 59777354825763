import { toZonedTime } from "date-fns-tz"
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval"
import isSunday from "date-fns/isSunday"
import isAfter from "date-fns/isAfter"
import endOfYear from "date-fns/endOfYear"
import differenceInYears from "date-fns/differenceInYears"
import groupBy from "lodash/groupBy"
import format from "date-fns/format"

const holidays = {
    GR: [
        {
            year: 2023,
            dates: [
                "Jan 1",
                "Jan 6",
                "Feb 27",
                "Mar 25",
                "Apr 14",
                "Apr 17",
                "May 1",
                "Jun 4",
                "Jun 5",
                "Aug 15",
                "Oct 28",
                "Dec 25",
                "Dec 26",
            ],
        },
        {
            year: 2024,
            dates: [
                "Jan 1",
                "Jan 6",
                "Mar 18",
                "Mar 25",
                "May 1",
                "May 3",
                "May 6",
                "Jun 23",
                "Jun 24",
                "Aug 15",
                "Oct 28",
                "Dec 25",
                "Dec 26",
            ],
        },
    ],
    CA: [
        {
            year: 2023,
            dates: ["Jan 1", "Apr 7", "May 22", "Jul 1", "Aug 7", "Sep 4", "Sep 30", "Oct 9", "Nov 11", "Dec 25"],
        },
        {
            year: 2024,
            dates: ["Jan 1", "Mar 29", "May 20", "Jul 1", "Aug 5", "Sep 2", "Sep 30", "Oct 14", "Nov 11", "Dec 25"],
        },
    ],
    FR: [
        {
            year: 2023,
            dates: [
                "Jan 1",
                "Apr 9",
                "Apr 10",
                "May 1",
                "May 8",
                "May 18",
                "May 29",
                "Jul 14",
                "Aug 15",
                "Nov 1",
                "Nov 11",
                "Dec 25",
            ],
        },
        {
            year: 2024,
            dates: [
                "Jan 1",
                "Mar 31",
                "Apr 1",
                "May 1",
                "May 8",
                "May 9",
                "May 20",
                "Jul 14",
                "Aug 15",
                "Nov 1",
                "Nov 11",
                "Dec 25",
            ],
        },
    ],
    UK: [
        {
            year: 2023,
            dates: ["Jan 2", "Apr 7", "Apr 10", "May 1", "May 8", "May 29", "Aug 28", "Dec 25", "Dec 26"],
        },
        {
            year: 2024,
            dates: ["Jan 1", "Mar 29", "Apr 1", "May 6", "May 27", "Aug 6", "Dec 25", "Dec 26"],
        },
    ],
    US: [
        {
            year: 2023,
            dates: ["Jan 1", "Jan 16", "Feb 20", "May 29", "Jul 4", "Sep 4", "Nov 10", "Nov 23", "Dec 25"],
        },
        {
            year: 2024,
            dates: ["Jan 1", "Jan 15", "Feb 19", "May 27", "Jul 4", "Sep 2", "Nov 11", "Nov 28", "Dec 25"],
        },
    ],
}

const getGroupedYears = (code) => {
    const group = holidays[code].map((o) => {
        return {
            year: o.year,
            dates: o.dates.map((d) => new Date(d + " " + o.year)),
        }
    })

    return groupBy(group, "year")
}

function getAllStatDays(code) {
    if (!holidays[code]) return []

    let days = []
    holidays[code].forEach((hd, i) => {
        days = [...days, ...hd.dates.map((dd) => new Date(dd + " " + hd.year))]
    })

    return days
}

const formulas = {
    GR: greekRules,
}

function greekRules({ person, orgData, year, employmentType = "indefinite", holidays }) {
    if (!person.startDate) {
        return 0
    }

    const startDate = toZonedTime(person.startDate)

    const preService = person.yearsOfPreService || "0-0-0"
    const calculationYear = year

    const startYear = new Date(startDate).getFullYear()
    const preServiceYears = convertPreServiceToYears(preService)
    const yearsWorked = calculationYear - startYear

    let totalYearsWorked = preServiceYears + yearsWorked

    const has10YearsAtCompany = differenceInYears(new Date(), toZonedTime(person.startDate)) >= 10

    const has12Years = (totalYearsWorked >= 12 && totalYearsWorked < 25) || has10YearsAtCompany
    const has25Years = totalYearsWorked >= 25

    const calculatedLeave = calculateAnnualLeave({
        yearsWorked,
        has12Years,
        has25Years,
        holidays,
        orgData,
        startDate,
        year,
        orgPerson: person,
    })

    return calculatedLeave
}

const holidayCodes = {
    GR: [
        {
            id: "annual-leave",
            fixed: true,
            code: "ALV",
            description: "Annual leave",
            days: [], //formula
            isProjectLevel: true,
        },
        {
            id: "sick-leave",
            fixed: true,
            code: "SLV",
            description: "Sick leave",
            days: [],
        },
        {
            id: "maternity-leave",
            fixed: true,
            code: "MTR",
            description: "Maternity and paternity",
            days: [
                {
                    year: 2023,
                    amount: 119,
                },
                {
                    year: 2024,
                    amount: 119,
                },
            ],
        },
        {
            id: "marriage-leave",
            fixed: true,
            code: "MRG",
            description: "Marriage leave",
            days: [
                {
                    year: 2023,
                    amount: 5,
                },
                {
                    year: 2024,
                    amount: 5,
                },
            ],
        },
        {
            id: "bereave-leave",
            fixed: true,
            code: "BRV",
            description: "Bereavement",
            days: [
                {
                    year: 2023,
                    amount: 2,
                },
                {
                    year: 2024,
                    amount: 2,
                },
            ],
        },
        {
            id: "elect-leave",
            fixed: true,
            code: "ELCT",
            description: "Election leave",
            days: [
                {
                    year: 2023,
                    amount: 3,
                },
                {
                    year: 2024,
                    amount: 3,
                },
            ],
        },
    ],
}

export { holidays, formulas, holidayCodes, getAllStatDays }

function calculateAnnualLeave({ yearsWorked, has12Years, has25Years, orgPerson, orgData, startDate, year }) {
    const std = toZonedTime(orgPerson.startDate)

    if (isAfter(std, endOfYear(startDate))) {
        return 0
    }

    const isSix = orgData.daysPerWeek >= 6

    if (yearsWorked === 0) {
        const months = eachMonthOfInterval({
            start: std,
            end: endOfYear(startDate),
        }).length

        let multiplier = !orgData.daysPerWeek || orgData.daysPerWeek === 5 ? 20 : 24

        if (has12Years) {
            if (!orgData.daysPerWeek || orgData.daysPerWeek === 5) {
                multiplier = 25
            } else if (orgData.daysPerWeek >= 6) {
                multiplier = 30
            }
        } else if (has25Years) {
            if (!orgData.daysPerWeek || orgData.daysPerWeek === 5) {
                multiplier = 26
            } else if (orgData.daysPerWeek >= 6) {
                multiplier = 31
            }
        }

        return Math.round(months * (multiplier / 12))
    } else if (yearsWorked === 1) {
        if (has12Years) return isSix ? 30 : 25
        if (has25Years) return isSix ? 31 : 26
        //FINALLY
        return isSix ? 25 : 21
    } else if (yearsWorked >= 2) {
        if (has12Years) return isSix ? 30 : 25
        if (has25Years) return isSix ? 31 : 26

        //FINALLY
        return isSix ? 26 : 22
    }
    return 0 // Default case, should not occur based on given scenarios
}

function convertPreServiceToYears(preService) {
    const [years, months, days] = preService.split("-").map(Number)
    return years + months / 12 + days / 365
}
