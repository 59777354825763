import api from '../api.service';

export async function johnDbOrgExtract() {
    return api
        .get(`/mx-admin/john/db/org/extract`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}
export async function licenseOrg(orgId) {
    return api
        .post(`/mx-admin/org/license/${orgId}`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function setOrgExtraFreeSeats(orgId, extraFreeSeats) {
    return api
        .put("/mx-admin/org/seats/free", {orgId, extraFreeSeats})
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function deleteAllOrgMissions(orgId) {
    return api
        .post('/mx-admin/org/delete/missions/all', {orgId})
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function permanentlyDeleteUser(userId) {
    return api
        .delete(`/mx-admin/user/${userId}`)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function getAppSumoActivationLink(email) {
    return api
        .post(`/mx-admin/appsumo/activation/link`, {email})
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}