import React from "react"
import onClickOutside from "react-onclickoutside"


class BlurHandler extends React.PureComponent {

    handleClickOutside = evt => {

        if(this.props.onBlur)
          this.props.onBlur(evt)
    }


    render() {
        
        return this.props.children
    }
}

export default onClickOutside(BlurHandler)
