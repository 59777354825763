import React from "react"
import className from "classnames"

export default class Button extends React.PureComponent {
    clickHandler(e) {
        e.preventDefault()

        if (this.props.disabled || this.clicked) {
            return
        }

        this.clicked = true

        if (this.props.onClick) {
            this.props.onClick(e)
        }

        setTimeout(() => {
            this.clicked = false
        }, 400)
    }
    render() {
        const cn = className(
            "dna-button",
            {
                "dna-button-clear": this.props.clear,
                "dna-button-block": this.props.block,
                "dna-space-right": this.props.spaceRight,
                "dna-space-left": this.props.spaceLeft,
                clear: this.props.clear,
                "dna-dark-button": this.props.dark,
                "dna-accent-button": this.props.accent,
                "dna-lime-button": this.props.lime,
                "dna-mint-button": this.props.mint,
                "dna-red-button": this.props.red,
                "dna-button-disabled": this.props.disabled,
                "dna-danger-button": this.props.danger,
                "dna-orange-button": this.props.orange,
                "has-svg": this.props.icon,
                "has-animation": this.props.animated,
                "launch-me": this.props.vertical,
                "flip-me": this.props.flip,
            },
            this.props.className
        )
        const content = this.props.block ? <buttonkid>{this.props.children}</buttonkid> : this.props.children
        return (
            <div
                className={cn}
                onClick={this.clickHandler.bind(this)}
                style={this.props.style}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                {content}
            </div>
        )
    }
}
