import React, { useState, useEffect, useRef } from "react"
import "./Board.scss"
import { getRefId, isMissionComplete, getObjectId } from "../utils/utils"
import { missionWarnings } from "../utils/mission"
import Loadable from "react-loadable"
import Badge from "@material-ui/core/Badge"

import Tabs from "../comp/Tabs"
import { isBefore } from "date-fns/isBefore"

import {
    PiTriangleDuotone,
    PiClockDuotone,
    PiCreditCardDuotone,
    PiAirplaneDuotone,
    PiCalendarCheckDuotone,
} from "react-icons/pi"
import apiTimesheet from "../services/timesheet/timesheet.api"
import apiExpenses from "../services/expense/expense.api"
import apiPeopleLeave from "../services/peopleLeave/peopleLeave.api"
import { getLeaveDate } from "../comp/PeopleLeaveUtils"
import { updateStateTimesheets } from "../utils/timesheets"
import { updateStateLeaves } from "../utils/leaves"
import { updateStateExpenses } from "../utils/expenses"
import { removeParam } from "../comp/History"

const PmTimesheets = Loadable({
    loader: () => import("../comp/PmTimesheets"),
    loading: () => <span />,
})

const VisionBoard = Loadable({
    loader: () => import("../comp/VisionBoard"),
    loading: () => <span />,
})

const PmLeaves = Loadable({
    loader: () => import("../comp/PmLeaves"),
    loading: () => <span />,
})

const ResourceTimeline = Loadable({
    loader: () => import("../comp/ResourceTimeline"),
    loading: () => <span />,
})
const ExpenseTracker = Loadable({ loader: () => import("../comp/ExpenseTracker"), loading: () => <span /> })

const Board = ({ app, stateChange }) => {
    const [tabs, setTabs] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [pmMissions, setPmMissions] = useState([])
    const [licensedPmMissions, setLicensedPmMissions] = useState([])
    const [nonPmMissions, setNonPmMissions] = useState([])
    const [completeMissions, setCompleteMissions] = useState([])
    const [updateKey, setUpdateKey] = useState([])
    const [timesheets, setTimesheets] = useState([])
    const [expenses, setExpenses] = useState([])
    const [leaves, setLeaves] = useState([])
    const [warningData, setWarningData] = useState([])
    const timesheetsRef = useRef([])
    const expensesRef = useRef([])
    const leavesRef = useRef([])

    const changeTab = (indx) => {
        removeParam("board-tab")
        localStorage.setItem("x-board-tab", indx)
        setTabIndex(indx)
    }

    useEffect(() => {
        if (licensedPmMissions.length === 0) {
            setTabIndex(0)
            return
        }

        const q = new URLSearchParams(window.location.search)
        if (q.has("board-tab")) {
            const index = tabs.findIndex((t) => t.id === q.get("board-tab"))
            setTabIndex(index === -1 ? 0 : index)
        } else {
            const savedTabIndex = localStorage.getItem("x-board-tab")

            if (+savedTabIndex) {
                setTabIndex(+savedTabIndex)
            }
        }
    }, [licensedPmMissions.length, tabs])

    useEffect(() => {
        const passAlong = {
            app,
            stateChange,
            context: "pm",
            getPmLeaves,
            asyncTimesheets,
            asyncExpenses,
            asyncLeaves,
            timesheets,
            leaves,
        }
        let myTabs = [
            {
                title: () => (
                    <>
                        <PiTriangleDuotone />
                        Mission.Vision.Board
                    </>
                ),
                comp: VisionBoard,
                notComponent: true,
                params: {
                    ...passAlong,
                    pmMissions: licensedPmMissions,
                    myMissions: nonPmMissions,
                    timesheets,
                },
            },
        ]

        if (licensedPmMissions.length) {
            myTabs = [
                ...myTabs,
                {
                    title: () => (
                        <Badge
                            badgeContent={timesheets?.filter((ts) => ts.status === "submitted")?.length}
                            color="primary"
                        >
                            <PiClockDuotone />
                            Timesheets
                        </Badge>
                    ),
                    comp: PmTimesheets,
                    notComponent: true,
                    id: "timesheets",
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        timesheets,
                        timesheetsRef,
                        updateTimesheetsInState,
                        updateKey,
                        context: "multi",
                    },
                },
                {
                    title: () => (
                        <Badge
                            badgeContent={expenses?.filter((ex) => ex.status === "submitted")?.length}
                            color="primary"
                        >
                            <PiCreditCardDuotone />
                            Expenses
                        </Badge>
                    ),
                    comp: ExpenseTracker,
                    id: "expenses",
                    notComponent: true,
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        context: "pm",
                        givenExpenses: expenses,
                        updateExpensesInState,
                    },
                },
                {
                    title: () => (
                        <Badge
                            badgeContent={
                                leaves?.filter(
                                    (l) =>
                                        !l.reviews.find((r) => r.by === app.state.person._id) &&
                                        !isBefore(getLeaveDate(l.dates[0].from), new Date())
                                ).length
                            }
                            color="primary"
                        >
                            <PiAirplaneDuotone />
                            Leaves
                        </Badge>
                    ),
                    comp: PmLeaves,
                    id: "leaves",
                    notComponent: true,
                    params: { ...passAlong, missions: licensedPmMissions, leaves, updateLeavesInState, asyncLeaves },
                },
                {
                    title: () => (
                        <>
                            <PiCalendarCheckDuotone />
                            Scheduling
                        </>
                    ),
                    comp: ResourceTimeline,
                    notComponent: true,
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        missionChange: stateChange,
                        leaveRequests: leaves,
                    },
                },
            ]
        }

        setTabs(myTabs)
    }, [stateChange.timestamp, updateKey])

    useEffect(() => {
        let pmM = []
        let teamM = []
        let completeM = []
        app.state.missions.forEach((m, i) => {
            const meInM = m.people.find((p) => getRefId(p) === app.state.person._id)

            if (m.isTemplate || meInM.permission > 3) return
            if (isMissionComplete(m)) {
                completeM.push(m)
                return
            }

            if (meInM) {
                if (meInM.isProjectManager || meInM.permission === 2) {
                    pmM.push(m)
                } else {
                    teamM.push(m)
                }
            }
        })

        setNonPmMissions(teamM)
        setPmMissions(pmM.map((m) => ({ ...m, isPm: true })))
        setCompleteMissions(completeM)
        setLicensedPmMissions(pmM.filter((m) => m.isLicensedActive && m.org))

        setUpdateKey(new Date().getTime())
    }, [app.state.missions.length, stateChange.timestamp])

    useEffect(() => {
        app.hideAppAdminTools()

        return () => {
            app.showAppAdminTools()
            removeParam("board-tab")
        }
    }, [])

    useEffect(() => {
        if (licensedPmMissions.length) {
            const wd = missionWarnings({ missions: licensedPmMissions, app })
            setWarningData(wd)
            asyncTimesheets()
            asyncExpenses()
            asyncLeaves()
        }
    }, [licensedPmMissions.length])

    const asyncTimesheets = async (force) => {
        const ts = await getPmTimesheets({ licensedPmMissions, force })

        updateTimesheetsInState({ how: "set", data: ts })
    }
    const asyncExpenses = async () => {
        const expen = await getPmExpenses({
            app,
            pmMissions: licensedPmMissions.filter((m) => {
                const org = app.state.orgs.find((o) => o._id === getObjectId(m.org))

                if (org.invoicingDisabled) {
                    return false
                }

                return true
            }),
        })
        updateExpensesInState({ how: "set", data: expen?.filter((ex) => ex.status !== "draft") })
    }
    const asyncLeaves = async (force) => {
        const leav = await getPmLeaves({ app, pmMissions: licensedPmMissions, force })

        updateLeavesInState({ how: "set", data: leav?.filter((lv) => lv.status !== "draft") })
    }
    const updateTimesheetsInState = ({ how, data }) => {
        const newSheets = updateStateTimesheets({
            timesheets: timesheetsRef.current,
            how,
            data,
        })

        setTimesheets(newSheets)
        timesheetsRef.current = newSheets
        setUpdateKey(new Date().getTime())
    }
    const updateExpensesInState = ({ how, data }) => {
        const newExpenses = updateStateExpenses({
            expenses: expensesRef.current,
            how,
            data,
        })

        setExpenses(newExpenses)
        expensesRef.current = newExpenses
        setUpdateKey(new Date().getTime())
    }
    const updateLeavesInState = ({ how, data }) => {
        const newLeaves = updateStateLeaves({
            leaves: leavesRef.current,
            how,
            data,
        })

        setLeaves(newLeaves)
        leavesRef.current = newLeaves
        setUpdateKey(new Date().getTime())
    }

    const MyTabs = Tabs({
        data: tabs,
        activeTabIndex: tabIndex,
        returnArray: true,
        bodyClassName: "x-board-panels",
        onChange: (i, data) => {
            changeTab(i, data)
        },
    })

    return (
        <div className="x-pm-board">
            {MyTabs[0]}
            {MyTabs[1]}
        </div>
    )
}
const getPmTimesheets = async ({ licensedPmMissions, force }) => {
    let byPass
    if (force) {
        const lf = localStorage.getItem("pm-ts-force")

        if (lf && new Date().getTime() - parseInt(lf) < 10000) {
            byPass = true
        } else {
            localStorage.setItem("pm-ts-force", new Date().getTime().toString())
        }
    }

    const lastUpdate = localStorage.getItem("pm-ts")
    if (byPass || (!force && lastUpdate && new Date().getTime() - parseInt(lastUpdate) < 300000)) {
        const rl = localStorage.getItem("pm-ts-list")

        return rl ? JSON.parse(rl) : []
    }

    const ts = await apiTimesheet.getProjectManagerTimesheets()
    let data = ts.filter((ts) => ts.mission && licensedPmMissions.find((m) => m._id === ts.mission._id))

    localStorage.setItem("pm-ts-list", JSON.stringify(data))
    localStorage.setItem("pm-ts", new Date().getTime().toString())

    return data
}
const getPmLeaves = async ({ app, pmMissions, force }) => {
    let results = []
    let byPass

    if (force) {
        const lf = localStorage.getItem("pm-leave-force")

        if (lf && new Date().getTime() - parseInt(lf) < 10000) {
            byPass = true
        } else {
            localStorage.setItem("pm-leave-force", new Date().getTime().toString())
        }
    }

    const lastUpdate = localStorage.getItem("pm-leaves")
    if (byPass || (!force && lastUpdate && new Date().getTime() - parseInt(lastUpdate) < 300000)) {
        const rl = localStorage.getItem("pm-leaves-list")

        return rl ? JSON.parse(rl) : []
    }

    try {
        results = await apiPeopleLeave.getProjectManagerLeaveRequestList()
    } catch (e) {
        app.silentFail("PM leaves list issue")
    }

    localStorage.setItem("pm-leaves-list", JSON.stringify(results))
    localStorage.setItem("pm-leaves", new Date().getTime().toString())

    return results
}
const getPmExpenses = async ({ app, pmMissions }) => {
    let results = []

    try {
        results = await apiExpenses.getProjectManagerExpenses()
    } catch (e) {
        app.silentFail("eoiwueExppps:" + e)
    }

    return results || []
}

export default Board
