import get from "lodash/get"
import { stripe } from "../services/stripe"
import { capitalizeFirstLetter, convertSize, getRefId } from "./Utils"
import { MISSION_PERMISSION } from "../services/mission/mission.const"
import { getTimeUtc } from "../utils/dates"
import { toast } from "react-toastify"
import { createSubscription } from "../services/org/org.stripe.api"

export function enableNoCCTrial({ org, app, onTrialStarted, cb }) {
    const aboutMe = app.state.person

    const meInOrg = org.people?.find((p) => getRefId(p) === aboutMe._id)

    if (!org || !org.people || !meInOrg?.permission === 2) {
        toast.error("Ooops. Please make sure you are authorized to administer this organization.")
        return
    }

    if (!aboutMe.emailVerified) {
        toast.error("So sorry but you must first verify your email address to proceed. This is to protect everyone.", {
            autoClose: 6000,
        })
        return
    }

    app.block("Initiating. Please stand by...")

    const idempotency_key = getIdempotencyKey(org._id)

    createSubscription(
        org._id,
        {
            //oobCode
            customer: {
                email: meInOrg.email,
                name: aboutMe.firstName + " " + aboutMe.lastName,
            },
        },
        {
            idempotency_key,
            isTrial: true,
        }
    )
        .then((response) => {
            app.unBlock()
            if (cb) cb(response)

            const orgChange = response.org
            app.orgUpdateState(org, orgChange)

            app.setState({
                hasTimeTracking: true,
            })

            const paymentMethod = getCustomerPaymentMethodDefault({
                customer: {
                    email: aboutMe.email,
                    name: aboutMe.firstName + " " + aboutMe.lastName,
                },
            })

            app.orgUpdate(org._id, {
                trialStartedOn: getTimeUtc(new Date()),
            })

            toast.success("Thank you! Let us know if we can help.")

            app.unBlock()
            if (onTrialStarted) onTrialStarted(response)
            //idempotency_key = getIdempotencyKey(org._id)
        })
        .catch((err) => {
            console.error(err)
            toast.error(err.message)
            app.unBlock()
        })
}

export function isStripeDefined() {
    return !!stripe
}

/**
 * Stripe uses timestamps without the milliseconds.
 * This method ensures that you always get back the proper unix timestamp.
 * @param timestamp {number}
 * @returns {number}
 */
export function getStripeTimestamp(timestamp) {
    return timestamp > 0 && timestamp.toString().length <= 10 ? 1000 * timestamp : timestamp
}

/**
 * Get customer subscription (we assign only one subscription per customer, so only the first one is returned)
 * @param customer {Object} Stripe customer
 * @return {Object|undefined}
 */
export function getCustomerSubscription(customer) {
    return get(customer, "subscriptions[0]")
}

/**
 * Get customer subscription items
 * @param customer {Object} Stripe customer
 * @return {Object[]|undefined}
 */
export function getCustomerSubscriptionItems(customer) {
    return get(customer, "subscriptions[0].items.data")
}

/**
 * Get Stripe customer currency.
 * @param customer
 * @return {any}
 */
export function getCustomerCurrency(customer) {
    const currency = get(customer, "currency")
    return typeof currency === "string" ? currency.toUpperCase() : undefined
}

/**
 * Get Stripe customer payment card
 * @param customer {Object} Stripe customer
 * @return {Object|undefined}
 */
export function getCustomerPaymentMethodDefault(customer) {
    const paymentMethod = get(customer, "invoice_settings.default_payment_method")
    return typeof paymentMethod === "object" ? paymentMethod : undefined
}

/**
 * Get Stripe customer payment card
 * @param customer {Object} Stripe customer
 * @return {Object|undefined}
 */
export function getCustomerPaymentBillingDetails(customer) {
    return get(customer, "invoice_settings.default_payment_method.billing_details")
}

/**
 * Get Stripe customer payment card
 * @param customer {Object} Stripe customer
 * @return {Object|undefined}
 */
export function getCustomerPaymentCard(customer) {
    const card = get(customer, "invoice_settings.default_payment_method.card")
    return typeof card === "string" ? undefined : card
}

/**
 * Get Stripe customer tax id list
 * @param customer {Object} Stripe customer
 * @return {Object|undefined}
 */
export function getCustomerTaxIdList(customer) {
    return get(customer, "tax_ids.data") || []
}

/**
 * @param paymentCard {Object}
 * @param paymentCard.brand {String}
 * @return {string}
 */
export function getPaymentCardBrand(paymentCard) {
    return capitalizeFirstLetter(get(paymentCard, "brand")) || "Unknown"
}

/**
 * @param paymentCard {Object}
 * @param paymentCard.last4 {String}
 * @return {string}
 */
export function getPaymentCardNumber(paymentCard) {
    return `•••• ${get(paymentCard, "last4")}`
}

/**
 * @param paymentCard {Object}
 * @param paymentCard.exp_month {Number}
 * @param paymentCard.exp_year {Number}
 * @return {string}
 */
export function getPaymentCardExpiry(paymentCard) {
    let month = get(paymentCard, "exp_month")
    let year = get(paymentCard, "exp_year")

    if (!month || !year) {
        return ""
    }

    if (month < 10) {
        month = `0${month}`
    }

    if (year > 99) {
        year = year.toString().substring(2)
    }

    return `${month}/${year}`
}

/**
 * @param paymentMethod {Object}
 * @return {string|undefined}
 */
export function getPaymentMethodAddress(paymentMethod) {
    const address = get(paymentMethod, "billing_details.address") || {}

    return ["line1", "line2", "city", "state", "postal_code", "country"]
        .map((field) => address[field])
        .filter((item) => !!item)
        .join(", ")
}

/**
 * Returns any failed card check keys.
 * https://stripe.com/docs/api/payment_methods/object#payment_method_object-card-checks
 * @param paymentMethod {Object}
 * @return {('address_line1_check'|'address_postal_code_check'|'cvc_check')[]}
 */
export function getPaymentMethodChecksFailedKeys(paymentMethod) {
    const checksObj = get(paymentMethod, "card.checks") || {}
    return Object.keys(checksObj).filter((key) => checksObj[key] === "fail")
}

/**
 * Returns a message string for all failed checks.
 * @param paymentMethod {Object}
 * @return {String|undefined}
 */
export function getPaymentMethodChecksFailedMessage(paymentMethod) {
    const failedCheckKeys = getPaymentMethodChecksFailedKeys(paymentMethod)

    if (!failedCheckKeys.length) {
        return
    }

    const names = failedCheckKeys.map((keyName) => {
        switch (keyName) {
            case "address_line1_check":
                return "address line 1"
            case "address_postal_code_check":
                return "address postal code"
            case "cvc_check":
                return "CVC code"
            default:
                return ""
        }
    })

    return `Validation failed for provided ${names.join(", ")}.`
}

/**
 * Get Mission's subscription plan item by Stripe's plan item id.
 * @param id {String} Stripe subscription plan item id
 * @param mission {Object}
 * @param mission.subscriptionPlanItems {Object[]}
 * @return {Object|undefined}
 */
export function getMissionSubscriptionPlanItemById(id, mission) {
    const missionSubscriptionPlanItems = get(mission, "subscriptionPlanItems")
    return (missionSubscriptionPlanItems || []).find((item) => item.id === id)
}

/**
 * Get Mission's subscription plan item by plan item type.
 * @param type {String} Subscription plan item type
 * @param mission {Object}
 * @param mission.subscriptionPlanItems {Object[]}
 * @return {Object|undefined}
 */
export function getMissionSubscriptionPlanItemByType(type, mission) {
    const missionSubscriptionPlanItems = get(mission, "subscriptionPlanItems")
    return (missionSubscriptionPlanItems || []).find((item) => item.type === type)
}

/**
 * Get Mission usage depending on subscription plan item type.
 * @param subscriptionPlanItemType {'people'|'storage'|'time_tracking'}
 * @param mission {Object}
 * @param mission.subscriptionPlanItems {Object[]}
 * @param mission.usageStorage {Number}
 * @param mission.people {Object[]}
 * @param mission.createdAt {Date}
 * @return {undefined|number|*}
 */
export function getMissionUsageByType(subscriptionPlanItemType, mission) {
    switch (subscriptionPlanItemType) {
        case "storage":
            let bytes = get(mission, "usageStorage") || 0
            let gigabytes = convertSize(bytes, "bytes", "GB")
            return {
                value: gigabytes,
                label: "GB",
            }
        case "people":
            let usagePeople = (get(mission, "people") || []).filter(
                (item) => item.permission !== MISSION_PERMISSION.OBSERVER_ORG_LIMITED
            )
            return {
                value: usagePeople.length,
                label: "people",
                isUnlimited: hasMissionUnlimitedPeopleUsage(mission),
            }
        case "time_tracking":
            let planItem = getMissionSubscriptionPlanItemByType("time_tracking", mission)
            return {
                value: null,
                label:
                    !!getMissionPromoByType(mission, "time_tracking") ||
                    (planItem && isSubscriptionPlanItemEnabled(planItem.id, mission))
                        ? "Enabled"
                        : "Disabled",
            }
        default:
            return {}
    }
}

/**
 * Get free usage percent value
 * @param usage {Number}
 * @param unitsFree {Number}
 * @return {Number}
 */
export function getMissionFreeUsagePercent(usage, unitsFree) {
    usage = usage || 0

    if (!unitsFree) {
        return 0
    }

    if (unitsFree < usage) {
        return 100
    }

    return (usage / unitsFree) * 100
}

export function getIdempotencyKey(id) {
    return id ? `${id}_${Date.now()}` : `date_${Date.now()}`
}

/**
 * Returns appropriate value for the usage.
 * @param value {Number} Units of usage
 * @return {string|number}
 */
export function getUsageValueFormatted(value) {
    if (!value && value !== 0) {
        return ""
    }

    if (typeof value !== "number" || value < 0.1) {
        return 0
    }

    if (Number.isInteger(value)) {
        return value
    }

    const valueStr = value.toString()
    return valueStr.slice(0, valueStr.indexOf(".") + 2)
}

/**
 * https://stripe.com/docs/api/payment_intents/object#payment_intent_object-last_payment_error
 * @param subscription
 * @return {Object|undefined}
 */
export function getSubscriptionLatestPaymentError(subscription) {
    return get(getSubscriptionLatestPaymentIntent(subscription), "last_payment_error")
}

/**
 * https://stripe.com/docs/api/payment_intents/object#payment_intent_object-next_action
 * @param subscription
 * @return {Object|undefined}
 */
export function getSubscriptionLatestPaymentNextAction(subscription) {
    return get(getSubscriptionLatestPaymentIntent(subscription), "next_action")
}

/**
 * https://stripe.com/docs/api/payment_intents
 * @param subscription
 * @return {Object|undefined}
 */
export function getSubscriptionLatestPaymentIntent(subscription) {
    return get(subscription, "latest_invoice.payment_intent")
}

/**
 * https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
 * @param subscription
 * @return {Object|undefined}
 */
export function getSubscriptionLatestPaymentIntentStatus(subscription) {
    return get(getSubscriptionLatestPaymentIntent(subscription), "status")
}

export function subscriptionRequiresAction(subscription) {
    return get(getSubscriptionLatestPaymentIntent(subscription), "status") === "requires_action"
}

export function hasMissionCustomer(mission) {
    return !!get(mission, "stripe.customerId")
}

export function hasOrgCustomer(org) {
    return !!get(org, "stripe.customerId")
}

export function hasMissionSubscription(mission) {
    return !!get(mission, "stripe.subscriptionId")
}

export function hasOrgSubscription(org) {
    return !!get(org, "stripe.subscriptionId")
}

/**
 * Returns true if the subscription plan item is enabled for the given mission.
 * @param subscriptionPlanItemId {String} Stripe plan item id
 * @param mission {Object}
 * @param mission.subscriptionPlanItems {Object[]}
 * @return {boolean}
 */
export function isSubscriptionPlanItemEnabled(subscriptionPlanItemId, mission) {
    if (!subscriptionPlanItemId || !mission) {
        return false
    }

    return !!getMissionSubscriptionPlanItemById(subscriptionPlanItemId, mission)
}

/**
 * Returns mission promo by type
 * @param mission {Object}
 * @param mission.promos {Object[]}
 * @param type {('people'|'storage'|'time_tracking')}
 * @return {string}
 */
export function getMissionPromoByType(mission, type) {
    return (get(mission, "promos") || []).find((promo) => promo.type === type)
}

/**
 * @param subscriptionPlanItemList {Object[]}
 * @param types {('people'|'storage'|'time_tracking')[]}
 * @return {Object[]}
 */
export function sortSubscriptionPlanItemsByType(subscriptionPlanItemList, types = []) {
    if (!Array.isArray(subscriptionPlanItemList) || !subscriptionPlanItemList.length) {
        return []
    }

    const subscriptionPlanItemListWithoutTypes = subscriptionPlanItemList.filter((item) => !types.includes(item.type))
    const subscriptionPlanItemListSorted = types
        .map((type) => (subscriptionPlanItemList || []).find((item) => item.type === type))
        .filter((item) => !!item)

    return [].concat(subscriptionPlanItemListSorted, subscriptionPlanItemListWithoutTypes)
}

export function getDateFromSeconds(seconds) {
    const date = new Date(0)
    date.setUTCSeconds(seconds)
    return date
}

/**
 * @param mission {Object}
 * @param mission.isProposal {boolean}
 * @param mission.isKeyLicensed {boolean}
 * @param mission.isOrgLicensed {boolean}
 * @param mission.isLicensedActive {boolean}
 * @param mission.createdAt {Date}
 * @return {Boolean}
 */
export function hasMissionUnlimitedPeopleUsage(mission) {
    return (
        get(mission, "isProposal") === true ||
        get(mission, "isKeyLicensed") === true ||
        get(mission, "isOrgLicensed") === true ||
        (get(mission, "promos") || []).some((promo) => promo.type === "people" && promo.unlimited)
    )
}

/**
 * Creates a new payment method with client side Stripe.
 * @param stripe {Object} Stripe property within the context of `Elements` (injectStripe)
 * @param userId {String} Currently logged in user id
 * @param missionId {String}
 * @param billingDetails {Object} Full list of params: https://stripe.com/docs/api/payment_methods/create
 * @param type {String} Payment method type. Defaults to "card"
 * @return {Promise<*>}
 */
export async function createPaymentMethod(stripe, userId, missionId, billingDetails, type = "card") {
    if (typeof userId !== "string") {
        throw new Error("User id was not provided to create payment method")
    }

    return stripe
        .createPaymentMethod("card", {
            // Full list of params: https://stripe.com/docs/api/payment_methods/create
            // Within the context of `Elements` (injectStripe), this call to createPaymentMethod knows from which Element to create the PaymentMethod.
            billing_details: billingDetails,
            metadata: {
                createdBy: userId,
                scope: "mission",
                missionId,
            },
        })
        .then(({ err, paymentMethod }) => {
            if (err || !paymentMethod) {
                throw err || new Error("Sorry but there was a problem validating the payment method.")
            }

            return paymentMethod
        })
}

/**
 * Creates a new payment method with client side Stripe.
 * @param stripe {Object} Stripe property within the context of `Elements` (injectStripe)
 * @param userId {String} Currently logged in user id
 * @param orgId {String}
 * @param billingDetails {Object} Full list of params: https://stripe.com/docs/api/payment_methods/create
 * @param type {String} Payment method type. Defaults to "card"
 * @return {Promise<*>}
 */
export async function createPaymentMethodOrg(stripe, userId, orgId, billingDetails, type = "card") {
    if (typeof userId !== "string") {
        throw new Error("User id was not provided to create payment method")
    }

    return stripe
        .createPaymentMethod("card", {
            // Full list of params: https://stripe.com/docs/api/payment_methods/create
            // Within the context of `Elements` (injectStripe), this call to createPaymentMethod knows from which Element to create the PaymentMethod.
            billing_details: billingDetails,
            metadata: {
                createdBy: userId,
                scope: "org",
                orgId,
            },
        })
        .then(({ err, paymentMethod }) => {
            if (err || !paymentMethod) {
                throw err || new Error("There was a problem validating a payment method. ")
            }

            return paymentMethod
        })
}

/**
 * Converts a Stripe's price or invoice amount to a formatted string.
 * E.g. getPriceAmountFormatted(7500) => "75.00"
 *
 * @param amount {number} Stripe price amount. E.g. For $75.00, the amount would be 7500
 * @param [locales] {string|string[]} A locale or array of locales to pass to Intl.NumberFormat()
 * @param [options] {object} Options to pass to Intl.NumberFormat()
 * @return {string}
 */
export function getPriceAmountFormatted(amount, locales = "en-US", options = {}) {
    return new Intl.NumberFormat(locales, {
        minimumFractionDigits: 2,
        ...options,
    }).format(amount / 100)
}
