import api from '../../api.service';



/**
 * Example usage:
 *
 * const chat = await maxChat(); // New chat session
 *
 * chat.onResponse((res) => {
 *     const {status, messageId, message, response} = res;
 *     // status: "responding", "done", "stopped" // When "responding", "response" is a stream chunk. When "done", "response" includes the complete stream result.
 *     // messageId: Uniquely identify the current message
 *     // message: Original message sent to the AI
 *     // response: In Markdown format. You will need to convert to HTML
 * });
 *
 * chat.onError((err) => {
 *     console.log('ERR:', err?.message);
 * });
 *
 * // Default role: user
 * chat.message('What project methodology is best for building a donut shop website?');
 *
 * // Custom role
 * chat.message({role: 'system', content: 'You are assisting a user on a project to build a donut shop website'});
 * chat.message({role: 'user', content: 'What methodology is best suited?'});
 *
 * chat.disconnect(); // IMPORTANT in order to clean up and to also stop the current chat session (so it doesn't respond within previous chat contexts)
 *
 */
export async function maxChat() {
    const socket = await api.socket.connect(`mAX/chat`);

    return {
        message: (msg) => {
            socket.emit('message', msg);
        },
        onResponse: (cb) => {
            socket.on('response', cb);
        },
        onError: (cb) => {
            socket.on('maxError', cb);
        },
        disconnect: () => {
            try {
                socket.disconnect();
            } catch (err) {
                // Nothing...
            }
        }
    };
}