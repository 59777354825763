import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import BlurHandler from "./BlurHandler"
import ReactDOM from "react-dom"
import cx from "classnames"
import "./Panel.scss"

import { IoChevronBack } from "react-icons/io5"
const Panel = ({
    open,
    children,
    maxWidth,
    zIndex = 10,
    onClose,
    style,
    right,
    portal,
    className,
    onBlur,
    outsideClickIgnoreClass,
}) => {
    let elComp = (
        <AnimatePresence>
            {open && (
                <motion.div
                    className={cx("x-panel", className, {
                        right: right,
                    })}
                    style={{ maxWidth: maxWidth, zIndex: zIndex, ...style }}
                    initial={{ transform: `translateX(${right ? "" : "-"}100%)`, opacity: 0 }}
                    animate={{ transform: "translateX(0%)", opacity: 1 }}
                    exit={{ transform: `translateX(${right ? "" : "-"}100%)`, opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                    <div className="dna-under-con x-panel-close" onClick={onClose}>
                        <IoChevronBack />
                    </div>

                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
    let comp
    if (onBlur) {
        comp = (
            <BlurHandler onBlur={onBlur} outsideClickIgnoreClass={outsideClickIgnoreClass}>
                {elComp}
            </BlurHandler>
        )
    } else {
        comp = elComp
    }

    if (portal) {
        return ReactDOM.createPortal(comp, document.body)
    }

    return comp
}

export default Panel
