import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import { motion, AnimatePresence } from "framer-motion"
import ActionItemTile from "../comp/ActionItemTile"
import ActionTile from "../comp/ActionTile"
import SimpleBarReact from "simplebar-react"
import Card from "../comp/Card"

import Tip from "../comp/Tip"
import Bubble from "../comp/Bubble"
import TaskTabs from "../comp/TaskTabs"
import uniqBy from "lodash/uniqBy"
import {
    PiTriangleDuotone,
    PiHexagonDuotone,
    PiNavigationArrowDuotone,
    PiIceCreamDuotone,
    PiChatCircleDotsDuotone,
    PiImageBrokenDuotone,
} from "react-icons/pi"
import { GiCosmicEgg } from "react-icons/gi"
import { toast } from "react-toastify"
import history, { addParam, removeParam } from "../comp/History"
import { getRefId, darkThemes } from "../utils/utils"
import apiPeopleLeave from "../services/peopleLeave/peopleLeave.api"
import { getLeaveDate } from "../comp/PeopleLeaveUtils"
import { AiTwotoneThunderbolt } from "react-icons/ai"
import TaskMaster from "../comp/TaskMaster"
import flatMap from "lodash/flatMap"
import { isAfter } from "date-fns/isAfter"
import Loadable from "react-loadable"
import { isUserEmailVerified } from "../comp/PeopleUtils"
import { recommender } from "../ai/recommender"
import "./Home.scss"

const DoAi = Loadable({
    loader: () => import("../comp/DoAi"),
    loading: () => <span />,
})

const TeamActivity = Loadable({
    loader: () => import("../comp/TeamActivity"),
    loading: () => <span />,
})

const WelcomeToMission = Loadable({
    loader: () => import("../module/WelcomeToMission"),
    loading: () => <span />,
})
const WelcomeToOrg = Loadable({
    loader: () => import("../module/WelcomeToOrg"),
    loading: () => <span />,
})
const PmApproveLeaveCard = Loadable({
    loader: () => import("../comp/PmApproveLeaveCard"),
    loading: () => <span />,
})

const Home = ({ app, notificationList, theMissionsHaveLanded, theTodosHaveLanded, missionChange }) => {
    const [leavesForReview, setLeavesForReview] = useState()

    const q = new URLSearchParams(window.location.search)

    const [myTheme, setMyTheme] = useState(
        typeof app.state.person.theme === "object" ||
            app.state.person.theme === null ||
            app.state.person.theme === "null" ||
            !app.state.person.theme
            ? "main-theme"
            : app.state.person.theme || localStorage.getItem("dna-x-theme") || "main-theme"
    )
    const [context, setContext] = useState(localStorage.getItem("x-context-home") || "workAI")
    const [recommendations, setRecommendations] = useState([])
    const recommendationsRef = useRef([])
    const [showMissionInvite, setShowMissionInvite] = useState()
    const [showOrgInvite, setShowOrgInvite] = useState()
    const [showLeaves, setShowLeaves] = useState()

    const inviteNoticeId = q.get("invite-notice")

    const setTheme = (theme) => {
        setMyTheme(theme || "empty")

        app.personUpdate({
            theme: theme || "empty",
        })
        localStorage.setItem("dna-x-theme", theme)
    }

    useEffect(() => {
        leaveChecks()
    }, [
        app.state.person?._id,
        notificationList.filter((l) => l.type === "leave_request_approval" || l.type === "leave_request_approval_final")
            .length,
    ])

    useEffect(() => {
        workManager()
    }, [notificationList.length, missionChange.timestamp, app.state.person?._id])

    useEffect(() => {
        recommendationsRef.current = recommendations || []
    }, [recommendations, app.state.person?._id])

    useEffect(() => {
        if (inviteNoticeId && recommendations.length) {
            const foundRecommendation = recommendations.find((r) => r.data?._id === inviteNoticeId)

            if (foundRecommendation?.isMissionInvite) {
                setShowMissionInvite(foundRecommendation)
            } else if (foundRecommendation?.isOrgInvite) {
                setShowOrgInvite(foundRecommendation)
            }

            removeParam("invite-notice")
        }
    }, [inviteNoticeId, recommendations.length, app.state.person?._id])

    useEffect(() => {
        app.setState({
            theme: myTheme,
        })
    }, [myTheme])

    const workManager = () => {
        let actionItems = []
        let notifications = []
        let systemMessages = []

        //Notifications
        notifications = notificationList
            .filter((n) => n.isDisabled !== true && (n.type === "invite" || n.type === "orgInvite"))
            .map((n) => {
                return {
                    isOrgInvite: n.type === "orgInvite",
                    isMissionInvite: n.type === "invite",
                    data: n,
                }
            })

        //Helpers
        if (app.state.orgs.length === 0) {
            actionItems.push({ ...helperStartNewOrg, people: [app.state.person] })
        }

        if (app.state.missions.length === 0) {
            actionItems.push({ ...helperStartNewMission, people: [app.state.person] })
        } else {
            //Tasks
            const missionMap = Object.fromEntries(app.state.missions.map((item) => [item?._id, item]))
            const actionsMap = Object.fromEntries(
                app.state.missions
                    .flatMap((m) => m.planItems)
                    .flatMap((m) => m.actions)
                    .map((item) => [item?._id, item])
            )
            //Magic
            const recs = recommender(app, new Date()).map((ai) => {
                return {
                    ...ai,
                    missionData: missionMap[ai.missionId],
                    actionData: actionsMap[ai.actionId],
                    people: ai.people,
                    app: app,
                    onClick: () => {
                        app.showActionItemDetails(ai)
                    },
                }
            })

            actionItems = [...actionItems, ...(recs || [])]
        }

        if (app.state.missions.length === 0 && app.state.orgs.length === 0) {
            actionItems.push({ ...helperLearnAbout, people: [app.state.person] })
        }

        let finalRecommendations = [...notifications, ...actionItems]

        if (
            finalRecommendations.filter((h) => !h?._id?.includes("helper")).length === 0 &&
            app.state.missions.length > 0
        ) {
            finalRecommendations.push({ ...helperNothingPlanned, people: [app.state.person] })
        }

        if (app.state.person?.uiMessageFlags?.includes("appsumo")) {
            finalRecommendations.unshift({
                ...helperClaimAppSumo,
                people: [app.state.person],
                app: app,
            })
        }

        setRecommendations(finalRecommendations.slice(0, 5))
    }

    const leaveChecks = async () => {
        const myPmMissions = app.state.missions.filter((p) => {
            return (
                !p.isModel &&
                !p.isTemplate &&
                p.people?.find(
                    (pp) => getRefId(pp) === app.state.person?._id && (pp.isProjectManager || pp.permission === 2)
                )
            )
        })

        const leavesGotten = await Promise.allSettled(
            myPmMissions.map((mission, i) => {
                return apiPeopleLeave.getLeaveRequestMissionList(mission?._id)
            })
        )

        let leaves = []

        const meId = getRefId(app.state.person)

        leavesGotten.forEach((result, i) => {
            if (result.status === "fulfilled") {
                leaves = [...leaves, ...result.value].filter(
                    (l) =>
                        l.createdBy !== meId &&
                        l.status === "pending_approval" &&
                        isAfter(getLeaveDate(l.dates[0].from), new Date()) &&
                        !l.reviews.find((r) => r.by === meId)
                )
            }
        })

        const pmPeople = flatMap(myPmMissions, "people").filter((p) => p.permission < 3)
        let cleanLeaves = []
        let pe = []

        leaves.forEach((l, i) => {
            const mPerson = pmPeople?.find((p) => getRefId(p) === getRefId(l.createdBy))
            if (mPerson) {
                cleanLeaves.push(l)
                pe.push(mPerson)
            }
        })

        cleanLeaves = uniqBy(cleanLeaves, "_id")

        if (cleanLeaves.length) {
            if (!recommendationsRef.current?.find((l) => l._id === "helper-leaves")) {
                let newRecommendations = recommendationsRef.current?.slice()

                newRecommendations.unshift(
                    tileHelperLeaves(
                        () => {
                            setShowLeaves(true)
                        },
                        [app.state.person],
                        cleanLeaves
                    )
                )

                setRecommendations(newRecommendations)
            }
        } else {
            let newRecommendations2 = recommendationsRef.current.slice()
            let helperIndex = newRecommendations2.findIndex((l) => l._id === "helper-leaves")

            if (helperIndex !== -1) {
                newRecommendations2.splice(helperIndex, 1)
                setRecommendations(newRecommendations2)
            }
        }

        const q = new URLSearchParams(window.location.search)
        if (q.has("leave-notice") && !cleanLeaves?.length) {
            removeParam("leave-notice")

            history.push("/board?board-tab=leaves")
        }

        setLeavesForReview(cleanLeaves || [])
    }

    const cn = cx("dna-home", myTheme || "main-theme")

    const isDark = darkThemes.includes(myTheme)

    return (
        <>
            <div className={cn}>
                <HomeMenu setContext={setContext} context={context} app={app} isDark={isDark} />
                {context === "workAI" && (
                    <>
                        <SimpleBarReact className="x-home-scroll">
                            <AnimatePresence exitBeforeEnter>
                                <motion.ul className="x-work-ai" layout style={{ width: 220, height: 400 }}>
                                    {recommendations.slice(0, 6).map((r, i) => {
                                        return (
                                            <TileComp
                                                key={r._id}
                                                recommendation={r}
                                                index={i}
                                                me={app.state.person}
                                                setShowMissionInvite={setShowMissionInvite}
                                                setShowOrgInvite={setShowOrgInvite}
                                            />
                                        )
                                    })}
                                </motion.ul>
                            </AnimatePresence>
                        </SimpleBarReact>
                        <TeamActivity app={app} isDark={isDark} />
                        <TaskTabs app={app} isDark={isDark} />
                    </>
                )}

                <AnimatePresence>
                    {context === "doAI" && (
                        <motion.div
                            className={isDark ? "dna-dark" : undefined}
                            transition={{ duration: 1 }}
                            initial={{ opacity: 0, zIndex: 0 }}
                            animate={{ opacity: 1, zIndex: 0 }}
                            exit={{ opacity: 0, zIndex: 0 }}
                        >
                            <DoAi app={app} />
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {context === "taskMaster" && (
                        <motion.div
                            className={isDark ? "dna-dark" : undefined}
                            transition={{ duration: 2, type: "spring", bounce: 0.5 }}
                            initial={{ opacity: 0, zIndex: 0, transform: "translateY(160px)" }}
                            animate={{ opacity: 1, zIndex: 0, transform: "translateY(80px)" }}
                            exit={{ opacity: 0, zIndex: 0, transform: "translateY(160px)" }}
                        >
                            <TaskMaster app={app} stateChange={missionChange} />
                        </motion.div>
                    )}
                </AnimatePresence>

                <Bubble
                    app={app}
                    tag="x-home-tour-nav"
                    x={50}
                    y={70}
                    top={0}
                    delay={0}
                    position="fixed"
                    arrow={"top"}
                    msg={
                        <>
                            <p>
                                <b>Control missionX</b> <PiNavigationArrowDuotone color="#82ffb0" />
                            </p>
                            <p>
                                <p>Navigate anywhere, create things, view your projects and check notifications.</p>
                            </p>
                        </>
                    }
                />
                <Bubble
                    app={app}
                    tag="x-home-tour-admin"
                    x={0}
                    y={0}
                    top={80}
                    right={90}
                    delay={0.3}
                    position="fixed"
                    arrow={"right"}
                    msg={
                        <>
                            <p>
                                Your admin tools <PiIceCreamDuotone color="pink" />
                            </p>
                            <p>Time tracking, leave requests, expenses, profile settings, and calendar.</p>
                        </>
                    }
                />
                {app.hasSeenMsg("x-home-tour-admin") && (
                    <Bubble
                        app={app}
                        tag="x-home-tour-tasks"
                        x={-30}
                        y={60}
                        top={0}
                        left={"50%"}
                        delay={0.3}
                        position="fixed"
                        arrow={"top"}
                        msg={
                            <>
                                <p>
                                    Work management <AiTwotoneThunderbolt color="gold" />
                                </p>
                                <p>
                                    View your daily curated tasks or switch to Task master for more power and control in
                                    viewing task updates across projects and people.
                                </p>
                            </>
                        }
                    />
                )}

                {!app.state.board && !app.state.timeTracking && (
                    <>
                        {context !== "taskMaster" && (
                            <Eggs setTheme={setTheme} app={app} isDark={isDark || myTheme === "japan-theme"} />
                        )}

                        <Tip
                            title="Master the missionX platform by exploring the system's manual"
                            className="dna-cursor x-help-trigger"
                            onClick={() => {
                                window.open("https://www.missionx.ai/guide/", "_blank")
                            }}
                        >
                            <img src="/img/astro-helps.png" />
                        </Tip>
                    </>
                )}
            </div>

            <Card
                closeButton
                onClose={() => {
                    setShowMissionInvite(false)
                    setShowOrgInvite(false)
                }}
                style={{ width: 500 }}
                className="dna-dark dna-dark-bg"
                open={Boolean(showMissionInvite) || Boolean(showOrgInvite)}
                center
                portal
                modal
            >
                <SimpleBarReact style={{ maxHeight: "95vh" }}>
                    {showMissionInvite && (
                        <WelcomeToMission
                            invite={showMissionInvite.data}
                            app={app}
                            onExit={() => {
                                setShowMissionInvite(false)
                            }}
                        />
                    )}
                    {showOrgInvite && (
                        <WelcomeToOrg
                            invite={showOrgInvite.data}
                            app={app}
                            onExit={(orgData) => {
                                setShowOrgInvite(false)
                            }}
                        />
                    )}
                </SimpleBarReact>
            </Card>

            <PmApproveLeaveCard
                app={app}
                open={showLeaves}
                leavesForReview={leavesForReview}
                setLeavesForReview={setLeavesForReview}
                onClose={() => {
                    leaveChecks()
                    removeParam("leave-notice")
                    setShowLeaves(false)
                }}
            />
        </>
    )
}

const Eggs = ({ setTheme, app, isDark }) => {
    return (
        <>
            <ul className={isDark ? "egg-shells dna-dark" : "egg-shells"}>
                {app.state.person.emailList.find((e) => e.email.includes("catchengineering")) && (
                    <li
                        onClick={(e) => {
                            app.xBurst(e.pageX, e.pageY)
                            setTheme("catch-theme")
                        }}
                    >
                        <GiCosmicEgg />
                    </li>
                )}
                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("main-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>

                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("japan-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>
                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("peak-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>

                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("banksy-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>
                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("stars-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>
                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("retro-tech-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>
                <li
                    onClick={(e) => {
                        app.xBurst(e.pageX, e.pageY)
                        setTheme("pool-days-theme")
                    }}
                >
                    <GiCosmicEgg />
                </li>
                <li
                    onClick={(e) => {
                        app.circleBurst(e.pageX, e.pageY)
                        setTheme(null)
                    }}
                >
                    <GiCosmicEgg />
                </li>
                <Bubble
                    app={app}
                    tag="x-home-tour-eggs"
                    x={-60}
                    y={-195}
                    delay={0.4}
                    arrow={"down"}
                    msg={
                        <>
                            <p>
                                <b>Change your theme</b> <PiImageBrokenDuotone color="#82ffb0" />
                            </p>
                            <p>
                                <p>Personalize your background theme.</p>
                            </p>
                        </>
                    }
                />
            </ul>
        </>
    )
}

const HomeMenu = ({ setContext, context, app, isDark }) => {
    return (
        <ul className={cx("x-home-nav", { "dna-dark": isDark })}>
            <li
                className={context === "workAI" ? "active" : undefined}
                onClick={(e) => {
                    setContext("workAI")
                    localStorage.setItem("x-context-home", "workAI")
                    app.xBurst(e.pageX, e.pageY)
                }}
            >
                <PiTriangleDuotone /> Work AI
            </li>

            <li
                className={context === "taskMaster" ? "active" : undefined}
                onClick={(e) => {
                    setContext("taskMaster")
                    localStorage.setItem("x-context-home", "taskMaster")
                    app.xBurst(e.pageX, e.pageY)
                }}
            >
                <PiHexagonDuotone /> Task master
            </li>
            <li
                className={context === "doAI" ? "active" : undefined}
                onClick={(e) => {
                    setContext("doAI")
                    localStorage.setItem("x-context-home", "doAI")
                    app.xBurst(e.pageX, e.pageY)
                }}
            >
                <PiChatCircleDotsDuotone /> Do AI
            </li>
        </ul>
    )
}

const TileComp = ({ recommendation, index, setShowMissionInvite, setShowOrgInvite, me }) => {
    //  const [isOpen, setIsOpen] = useState(false)

    let x = 0,
        scale = 1

    switch (index) {
        case 0:
            break
        case 1:
            x = 235
            scale = 0.9
            break
        case 2:
            x = -224
            scale = 0.8
            break
        case 3:
            x = 432
            scale = 0.7
            break
        case 4:
            x = -400
            scale = 0.6
            break
        case 5:
            x = 580
            scale = 0.5
            break

        default:
    }

    return (
        <motion.li
            initial={{
                opacity: 0,
                y: 170,
                x: x,
                scale: scale,
            }}
            transition={{ duration: 1.8, delay: 0.1 * index, type: "spring", bounce: 0.5 }}
            animate={{
                opacity: 1,
                y: 0,
                x: x,
                scale: scale,
            }}
            exit={{ opacity: 0, y: 170 }}
        >
            <RecommendationComp
                recommendation={recommendation}
                setShowMissionInvite={setShowMissionInvite}
                setShowOrgInvite={setShowOrgInvite}
                me={me}
            />
        </motion.li>
    )
}

const RecommendationComp = ({ recommendation, setShowMissionInvite, setShowOrgInvite, me }) => {
    if (recommendation.isMissionInvite) {
        const data = {
            _id: recommendation.data._id,
            title: "Welcome to the mission.",
            onClick: () => {
                if (
                    recommendation?.data.ref.inviteeEmail &&
                    !isUserEmailVerified(me, recommendation?.data.ref.inviteeEmail)
                ) {
                    if (!new URLSearchParams(window.location.search).get("myspace")) {
                        toast.warning("Please verify your email first.", { autoClose: 6000 })
                        addParam("myspace", "open")
                    }
                    return
                }

                setShowMissionInvite(recommendation)
            },
            missionData: recommendation.data.mission,
            showMissionTitle: true,
            people: [me],
            progress: true,
            actionItems: [{ status: "open" }],
            fullBg: true,
        }
        return <ActionTile key={data._id} {...data} />
    }
    if (recommendation.isOrgInvite) {
        const data = {
            _id: recommendation.data._id,
            title: "Welcome to the organization.",
            onClick: () => {
                if (
                    recommendation?.data.ref.inviteeEmail &&
                    !isUserEmailVerified(me, recommendation?.data.ref.inviteeEmail)
                ) {
                    if (!new URLSearchParams(window.location.search).get("myspace")) {
                        toast.warning("Please verify your email first.", { autoClose: 6000 })
                        addParam("myspace", "open")
                    }
                    return
                }

                setShowOrgInvite(recommendation)
            },
            missionData: {
                theme: {
                    backgroundColor: "black",
                    backgroundImage: "url(/themes/orgs/org-1.jpg)",
                    dark: true,
                },
            },
            showMissionTitle: true,
            people: [me],
            progress: true,
            actionItems: [{ status: "open" }],
            fullBg: true,
        }
        return <ActionTile {...data} />
    }

    if (recommendation.from === "ai") {
        return <ActionTile {...recommendation} />
    }

    return <ActionItemTile {...recommendation} />
}

const helperClaimAppSumo = {
    _id: new Date().getTime() + "-spp-sumo-helper",
    title: "Claim your AppSumo purchase ❤️",
    showMissionTitle: true,
    msg: "You are the best. We will be the best for you.",
    progress: false,
    comp: ActionTile,
    from: "ai",
    fullBg: true,
    missionData: {
        title: "Activate",
        theme: {
            backgroundImage: "url(/x-themes/sumo.jpg)", //tile.jpg is added in ActionTile
            backgroundColor: "ffffff",
            dark: true,
        },
    },
    actionItems: [{ status: "open" }],
    onClick: (props) => {
        props.app.claimAppSumo()
    },
}

const helperStartNewOrg = {
    _id: "new-org-helper",
    title: "Establish your organization",
    showMissionTitle: true,
    msg: "Manage people, projects, processes, reports, clients and more...",
    progress: true,
    comp: ActionTile,
    from: "ai",
    fullBg: true,
    missionData: {
        title: "Next level",
        theme: {
            backgroundImage: "url(/x-themes/start-3.jpg)", //tile.jpg is added in ActionTile
            backgroundColor: "ffffff",
            dark: false,
        },
    },
    actionItems: [{ status: "open" }],
    onClick: () => {
        addParam("hq", "true")
    },
}

const helperStartNewMission = {
    _id: "new-mission-helper",
    title: "Create a project",
    showMissionTitle: true,
    msg: "Plan, budget and manage work to be done.",
    progress: true,
    actionItems: [{ status: "open" }],
    comp: ActionTile,
    from: "ai",
    fullBg: true,
    missionData: {
        title: "Quick start",
        theme: {
            backgroundImage: "url(/x-themes/start-1.jpg)", //tile.jpg is added in ActionTile
            backgroundColor: "ffffff",
            dark: false,
        },
    },
    onClick: () => {
        addParam("create-mission", "task")
    },
}

const helperLearnAbout = {
    _id: "vide-guides",
    title: "Getting started",
    msg: "Unlock powerful insights",
    showMissionTitle: true,
    fullBg: true,

    comp: ActionTile,
    from: "ai",
    missionData: {
        title: "Systems manual",
        theme: {
            backgroundImage: "url(/x-themes/start-2.jpg)", //tile.jpg is added in ActionTile
            backgroundColor: "ffffff",
            dark: false,
        },
    },
    progress: true,
    actionItems: [{ status: "open" }],
    onClick: () => {
        window.open("https://www.missionx.ai/guide", "_blank").focus()
    },
}

const tileHelperLeaves = (onClick, people, leavesForReview) => {
    return {
        _id: "helper-leaves",
        title: "Leave requests",
        msg: "Review the leave requests affecting your projects",
        showMissionTitle: true,
        fullBg: false,
        progress: true,
        comp: ActionTile,
        from: "ai",
        people: people,
        isAdminTask: true,
        missionData: {
            title: "For the PM",
            theme: {
                backgroundImage: "url(/x-themes/leaves.jpg)", //stupid -tile added at end... so stupid makis
                backgroundColor: "8bb6bf",
            },
        },
        actionItems: leavesForReview,
        onClick: onClick,
    }
}

const helperNothingPlanned = {
    _id: "check-on-plans",
    title: "Check on your projects?",
    msg: "No tasks planned for today.",
    showMissionTitle: true,
    progress: true,
    fullBg: true,
    from: "ai",
    missionData: {
        title: "Idea",
        theme: {
            backgroundImage: "url(/x-themes/start-4.jpg)", //tile.jpg is added in ActionTile
            backgroundColor: "ffffff",
            dark: false,
        },
    },
    actionItems: [{ status: "open" }],
    onClick: () => {
        history.push("/board")
    },
}

export default Home
