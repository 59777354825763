import React from "react"
import { Tooltip } from "react-tippy"

const Tip = ({
    title,
    position = "top",
    theme,
    children,
    red,
    light,
    id,
    blue,
    open,
    className,
    hideDelay,
    interactive,
    interactiveBorder,
    offset,
    sticky,
    html,
    delay,
    disabled,
    followCursor,
    style,
    stickyDuration,
    animation = "shift",
    onShown,
    onHidden,
    onHide,
    onShow,
    onRequestClose,
    hideOnClick,
    distance,
    popperOptions,
    noFlip,
    onClick,
    empty,
    leftText,
}) => {
    if (empty) {
        return children
    }
    if (noFlip) {
        popperOptions = {
            ...popperOptions,
            modifiers: {
                preventOverflow: {
                    enabled: false,
                },
                flip: {
                    enabled: false,
                },
            },
        }
    }

    return (
        <Tooltip
            title={title}
            className={"t-tip" + (className ? " " + className : "")}
            open={open}
            html={html}
            disabled={disabled}
            distance={distance}
            hideOnClick={hideOnClick}
            hideDelay={hideDelay}
            stickyDuration={stickyDuration}
            sticky={sticky}
            interactiveBorder={interactiveBorder}
            followCursor={followCursor}
            position={position}
            onRequestClose={onRequestClose}
            animation={animation}
            animateFill={false}
            popperOptions={popperOptions}
            theme={
                red
                    ? "dna-tootip-red"
                    : blue
                    ? "dna-tootip-blue"
                    : light
                    ? "dna-tooltip-light"
                    : leftText
                    ? "dna-tooltip-left"
                    : "dna-tootip"
            }
            inertia={true}
            delay={delay}
            onHide={onHide}
            onHidden={onHidden}
            interactive={interactive}
            flip={false}
            offset={offset}
            style={style}
            onShown={onShown}
            onShow={onShow}
        >
            {onClick ? (
                <span id={id} onClick={onClick}>
                    {children}
                </span>
            ) : (
                children
            )}
        </Tooltip>
    )
}

export default Tip
