import React, {useEffect, useReducer, useRef, useState} from "react"
import Button from "./Button"

import {permanentlyDeleteUser, setOrgExtraFreeSeats, licenseOrg, deleteAllOrgMissions, getAppSumoActivationLink} from "../services/admin/admin.api.js"
import {toast} from "react-toastify";
import {maxChat} from "../services/mAX/socket/maxChat";
import {getMissionAuditAdmin} from "../services/audit/audit.api";

const MxAdmin = ({app}) => {
    const [isLicensing, setIsLicensing] = useState(false);
    const [appSumoLink, setAppSumoLink] = useState(null);
    const [missionAuditList, setMissionAuditList] = useState([]);
    const chat = useRef(null);
    const chatResponse = useRef('');
    const chatCurrentMessageId = useRef(null);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        maxChat().then(res => {
            chat.current = res;

            chat.current.onResponse((res) => {
                const {status, messageId, response} = res;
                if (status === 'responding') {
                    if (messageId !== chatCurrentMessageId.current) {
                        chatCurrentMessageId.current = messageId;
                        chatResponse.current = `${chatResponse.current}</p>`;
                        chatResponse.current = `${chatResponse.current}<p style={{marginTop: '48px'}}>`;
                    }

                    chatResponse.current = `${chatResponse.current}${response}`;
                    forceUpdate();
                }
            });

            chat.current.onError((err) => {
                chatResponse.current = `ERR: ${err?.message}`;
                forceUpdate()
            });
        });

        return () => {
            chat.current?.disconnect();
        };
    }, []);

    const licenseDaOrg = () => {
        const input = document.getElementById("mxa-org-license")
        const orgId = input.value;
        input.value = '';

        if (!orgId) {
            return;
        }

        setIsLicensing(true);
        licenseOrg(orgId)
                .finally(() => setIsLicensing(false))
                .then(() => toast.success('Org licensed!'))
                .catch(err => toast.error(err.message));
    };

    const addUsers = () => {
        const el1 = document.getElementById("mxa-add-users-org")
        const el2 = document.getElementById("mxa-add-users")

        setOrgExtraFreeSeats(el1.value, +el2.value)
                .then((res) => {
                    alert("ok")
                })
                .catch((err) => alert(err.message))
    }

    const getAppSumoLink = () => {
        setAppSumoLink(null);
        const el = document.getElementById("mxa-app-sumo-email")

        getAppSumoActivationLink(el.value)
                .then((res) => {
                    setAppSumoLink(res.link)
                })
                .catch((err) => setAppSumoLink(err.message))
    }

    const fetchAuditMission = async () => {
        const el = document.getElementById("mxa-audit-m")
        const auditRes = await getMissionAuditAdmin(el.value, {skip: 30});
        console.log(auditRes.list);
    }

    const sendMessageToMax = () => {
        const el = document.getElementById("mxa-max-chat")
        chat.current?.message(el.value);
        el.value = '';
    }

    return (
            <div className="dna-col">
                ---
                <br/>
                <label>Mission Audit</label>
                <div>
                    <input id="mxa-audit-m" placeholder="Mission ID"/>
                </div>
                <Button onClick={fetchAuditMission}>Fetch</Button>

                <br />
                <div>{missionAuditList.length > 0 && <strong>Check console log</strong>}</div>
                <br/>
                ---
                ---
                <br/>
                <label>mAX (ChatGPT chat)</label>
                <div>
                    <input id="mxa-max-chat" placeholder="Who are you?"/>
                </div>
                <Button onClick={sendMessageToMax}>Send message</Button>

                <br />
                <div dangerouslySetInnerHTML={{__html: chatResponse.current}} />

                <br/>
                ---
                <br/>
                <label>AppSumo activation link</label>
                <div>
                    <input id="mxa-app-sumo-email" placeholder="Email"/>
                </div>
                <Button onClick={getAppSumoLink}>Get link</Button>

                <br />
                {!!appSumoLink && <p>{appSumoLink}</p>}

                <br/>
                ---
                <label htmlFor='mxa-org-license'>Fully license an org, forever.</label>
                <div>
                    <input id="mxa-org-license" placeHolder="Org id"/>
                </div>
                <Button disabled={isLicensing === true} onClick={licenseDaOrg}>License</Button>
                {isLicensing === true && <p><strong>Licensing... This can take up to 30 seconds. Make some instant coffee.</strong></p>}
                <br/>
                ---
                <br/>
                <label>Add org additional user seats</label>
                <div>
                    <input id="mxa-add-users-org" placeHolder="Org id"/>
                    <input id="mxa-add-users" placeHolder="# of seats"/>
                </div>
                <Button onClick={addUsers}>Add seats</Button>

                <br/>
                ---
                <br/>

                <label>(CAUTION!) Permanently delete a user (CAUTION!)</label>
                <small>Deletes a user from Firebase/MongoDB and removes the user from all Orgs, Missions and any other
                    documents the user was included.</small>
                <div>
                    <input id="mxa-delete-user-id" placeHolder="User ID"/>
                </div>
                <Button onClick={() => permanentlyDeleteUser(document.getElementById("mxa-delete-user-id").value)
                        .then(() => alert("ok"))
                        .catch(err => alert(err.message))}>Delete</Button>

                <br/>
                ---
                <br/>

                <label>(CAUTION!) Permanently delete all org's missions (CAUTION!)</label>
                <small>Deletes ALL missions for an org.</small>
                <div>
                    <input id="mxa-delete-org-missions-id" placeHolder="Org ID"/>
                </div>
                <Button onClick={() => deleteAllOrgMissions(document.getElementById("mxa-delete-org-missions-id").value)
                        .then(() => alert("ok"))
                        .catch(err => alert(err.message))}>Delete</Button>
            </div>
    )
}

export default MxAdmin
