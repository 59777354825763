import api from '../api.service';

/**
 * @param missionId {string}
 * @param [options] {object}
 * @param [options.sort] {'asc'|'desc'} Default to desc (latest change first)
 * @param [options.skip] {number} How many documents to skip. Defaults to 0.
 * @param [options.limit] {number} Number of documents to return. Defaults to 30 which is the Firestore limit. Greater than that and requests will fire in batches (slower performance)
 * @return {Promise<*>}
 */
export async function getMissionAudit(missionId, options = {}) {
    return api
        .post(`/history/changes/mission/${missionId}`, options)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function getMissionAuditAdmin(missionId, options = {}) {
    return api
        .post(`/history/admin/changes/mission/${missionId}`, options)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}
