import { getRefId, ensureNumber, getMissionStatus } from "../utils/utils"
import { isAfter } from "date-fns/isAfter"
import { isBefore } from "date-fns/isBefore"
import { differenceInDays } from "date-fns/differenceInDays"
import { isWithinInterval } from "date-fns/isWithinInterval"
import { findAiEndDate } from "../utils/dates"
import { toZonedTime } from "date-fns-tz"
import flatMap from "lodash/flatMap"

export function recommender(app, date) {
    const personId = app.state.person._id
    const currentDate = new Date()

    const myMissions = app.state.missions.filter((m) => {
        const status = getMissionStatus(m)

        let isWithin
        try {
            isWithin = isWithinInterval(date, {
                start: toZonedTime(m.planStartDate),
                end: toZonedTime(m.planEndDate),
            })
        } catch (e) {
            app.silentFail(`weo3r43i8883-2-mission${m?._id}`)
            return false
        }

        if (status !== "active" || m.isModel || m.isTemplate || !isWithin) {
            return false
        }

        const person = m.people?.find((p) => getRefId(p) === personId)
        return person && person.permission < 3
    })

    const missionMap = new Map(myMissions.map((m) => [m._id, m]))

    const flatPlanItems = flatMap(myMissions, "planItems")
    const flatPlanRoles = flatPlanItems.filter(
        (p) => p.type === "person" && p.person === personId && p.raciOnTasks?.length
    )

    const flatActions = flatMap(
        flatPlanItems.filter((p) => p.type === "phase"),
        "actions"
    )

    const allActionItems = flatMap(flatActions, "actionItems")

    const myActionItems = new Set()
    allActionItems.forEach((ai) => {
        if (ai && ai.people?.includes(personId) && ai.status !== "done") {
            const mission = missionMap.get(ai.missionId)
            if (mission && mission.projectType !== "mx-gant" && ai.week) {
                myActionItems.add(ai)
            }
        }
    })

    flatPlanRoles.forEach((pi) => {
        if (missionMap.get(pi.missionId)?.projectType === "mx-gantt") {
            pi.raciOnTasks.forEach((pr) => {
                const foundAi = allActionItems.find((a) => a._id === pr.taskId)
                if (foundAi && foundAi?.status !== "done") {
                    myActionItems.add(foundAi)
                }
            })
        }
    })

    const weightedActionItems = Array.from(myActionItems).map((ai) => {
        const endDate = ai.week || ai.startDate ? findAiEndDate(ai) : null
        const overDueAmount = !endDate ? 0 : isAfter(currentDate, endDate) ? differenceInDays(currentDate, date) : 0

        if (overDueAmount > 20) {
            return { ...ai, weight: 0 }
        }

        let weight = 1
        const mission = missionMap.get(ai.missionId)

        if (mission.client) {
            weight += 2
        }

        const closeToEndDate = overDueAmount || isBefore(endDate, date) ? 0 : differenceInDays(endDate, date) * 0.1
        weight -= ensureNumber(closeToEndDate) * 0.3
        weight += ensureNumber(ai.prevEndDate?.length) * 0.2
        weight += ensureNumber(ai.complexityMultiplier || 10)
        weight += ensureNumber(ai.people?.length)
        weight += overDueAmount * 10

        if (ai.metaType === 0) {
            weight += weight * 1.5
        }

        return { ...ai, weight }
    })

    return weightedActionItems.sort((a, b) => b.weight - a.weight)
}
