import cx from "classnames"
import numeral from "numeral"
import uniq from "lodash/uniq"
import groupBy from "lodash/groupBy"
import { toZonedTime } from "date-fns-tz"
import flatMap from "lodash/flatMap"
import eachWeekOfInterval from "date-fns/eachWeekOfInterval"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"
import addDays from "date-fns/addDays"
import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { isAfter } from "date-fns/isAfter"
import { isBefore } from "date-fns/isBefore"
import format from "date-fns/format"
import { getSmallestStartDate } from "../comp/DateUtils"

import React from "react"

export function formatTags(data = [], key = "title", id = "_id") {
    return data.map((d) => ({ name: d[key], id: d[id], obj: d }))
}

export const getUtilization = ({
    holidays = {},
    people = [],
    productivePlanItems = [],
    orgData,
    startDate,
    endDate,
    productivePlanItemsByPerson,
}) => {
    let personUtilizationMap = {}

    if (!startDate || !endDate) {
        return personUtilizationMap
    }

    if (isAfter(startDate, endOfISOWeek(new Date()))) {
        return personUtilizationMap
    }

    if (isAfter(endDate, endOfISOWeek(new Date()))) {
        endDate = endOfISOWeek(new Date())
    }

    if (isAfter(startDate, endDate)) {
        return personUtilizationMap
    }

    people.forEach((per, i) => {
        let myEd = per.endDate ? toZonedTime(per.endDate) : endDate

        let overAllProductiveHours = 0

        const myPlanItems = productivePlanItemsByPerson[getRefId(per)]

        let mySmallestTimesheetDate = getSmallestStartDate(
            flatMap(myPlanItems || [], "timesheets")
                .filter((t) => !!t)
                .map((t) => ({ startDate: t.weekStart }))
        )
        let mySmallestRoleDate = getSmallestStartDate(myPlanItems || [])
        let personCreatedDate = per.createdAt ? new Date(per.createdAt) : null
        let personStartDate = per.startDate ? toZonedTime(per.startDate) : null

        let baseStart = personStartDate || personCreatedDate || startDate

        let smallestDate = new Date(
            Math.min(
                mySmallestTimesheetDate?.getTime() || Infinity,
                mySmallestRoleDate?.getTime() || Infinity,
                baseStart?.getTime()
            )
        )

        if (isAfter(smallestDate, endDate)) {
            personUtilizationMap[getRefId(per)] = 0
            return
        }

        if (isBefore(smallestDate, startDate)) {
            smallestDate = startDate
        }

        let myWeeks = []

        if (isAfter(smallestDate, myEd)) {
            return
        }

        try {
            myWeeks = eachWeekOfInterval(
                {
                    start: smallestDate,
                    end: myEd,
                },
                { weekStartsOn: 1 }
            )
        } catch (e) {}

        myWeeks.forEach((week, i) => {
            const days = orgData?.daysPerWeek || 5
            let productiveHoursThisWeek = days * 8

            new Array(days).fill(1).forEach((d, i) => {
                const day = addDays(week, i)

                if (orgData) {
                    const isHoliday = holidays[`${orgData.holidayCountryCode}-${format(day, "d-MMM-yyyy")}`]

                    if (isHoliday) {
                        productiveHoursThisWeek -= 8
                    }
                }
            })

            overAllProductiveHours += productiveHoursThisWeek
        })

        const myMappedTsData = flatMap(myPlanItems || [], "timesheets")
            .filter((d) => !!d)
            .filter((t) => {
                return areIntervalsOverlapping(
                    {
                        start: toZonedTime(t.weekStart),
                        end: endOfISOWeek(toZonedTime(t.weekStart)),
                    },
                    {
                        start: startDate,
                        end: endDate,
                    }
                )
            })

        let myProductiveHours = myMappedTsData.reduce((cumm, obj) => (cumm += obj.totalHours), 0)

        const util = (myProductiveHours / overAllProductiveHours) * 100

        personUtilizationMap[getRefId(per)] = util
    })

    return personUtilizationMap
}

export const isMongoId = (id) => {
    const hexPattern = /^[a-f0-9]{24}$/

    return hexPattern.test(id)
}

export const isArchived = (obj) => {
    if (!obj) return null
    return obj.isArchived || obj._archived || obj.isDeleted
}

export const breakLines = (text) => {
    return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ))
}

export const trimToMaxChars = (text, maxChars) => {
    let count = 0
    let result = ""

    for (let char of text) {
        if (!/\s/.test(char)) {
            count++
        }
        if (count > maxChars) {
            result += "..."
            break
        }
        result += char
    }

    return result
}
export function stripHTML(html) {
    let doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
}

export function myActiveMissions({ missions, app }) {
    return missions.filter((m) => {
        const meInMission = m.people.find((p) => getRefId(p) === app.state.person._id)

        return meInMission && meInMission.permission <= 3
    })
}

export function sortAlpha(arr, key = "title") {
    if (!key) {
        alert("key is required")
        return arr
    }
    return arr?.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1
        }
        if (a[key] > b[key]) {
            return 1
        }
        return 0
    })
}

export function roundToDivisor(num, divisor) {
    return Math.round(num / divisor) * divisor
}

export function roundToMultiplier(num, multiplier) {
    return Math.ceil(num / multiplier) * multiplier
}

export function timeFormat(time) {
    if (time === 0 && time !== null) {
        return 0
    }
    if (time === null) {
        return ""
    }
    const dec = time.toString().split(".")[1]

    if (dec && dec.length) {
        if (dec.length === 1) {
            return numeral(time).format("0,0.0")
        }
        if (dec.length === 2) {
            return numeral(time).format("0,0.00")
        }
    }

    return numeral(time).format("0,0")
}

export function isMissionComplete(mission) {
    if (mission.status === "completed" || mission.status === "cancelled") {
        return true
    }
    if (mission.missionStop && mission.missionStop < new Date().getTime()) {
        return true
    }

    return false
}

export function getMissionPermissionLabel(person) {
    const { permission } = person
    if (permission === 2) {
        return "Admin 🔥"
    } else if (permission === 1 && person.isProjectManager) {
        return "Project manager"
    } else if (permission === 1) {
        return "Team lead"
    } else if (permission === 3) {
        return "Observer / Client"
    } else if (permission === 0) {
        return "Team member"
    } else if (permission === 4) {
        return "Organization management"
    }
}

export function getRolesText({ person, mission, orgData }) {
    let roles = []
    if (!person) {
        return []
    }
    const pis =
        mission?.planItems.filter((p) => p.type === "person" && p.person === getRefId(person) && !p.inactive) || []

    if (pis.length === 0) {
        return []
    }

    pis.forEach((pi, i) => {
        const orgRole = orgData?.roles?.find((r) => r._id === pi.role)

        if (orgRole) {
            roles.push(orgRole?.name || pi.title || "Unspecified role")
        } else {
            roles.push(pi.title || "Unspecified role")
        }
    })

    return uniq(roles)
}

export function getRefId(obj) {
    if (!obj) return

    if (typeof obj === "string") return obj

    if (!obj.ref && obj._id) return obj._id

    if (obj.ref && typeof obj.ref === "string") return obj.ref

    if (obj.ref && obj.ref._id) return obj.ref._id
}

export function getMissionTitle(mission) {
    if (mission?.projectCode) {
        return mission.projectCode + "-" + mission.title
    }
    return mission?.title || "Project not found..."
}

export function getMissionStatus(m, returnLabel) {
    if (m.projectStatus === "open") {
        return returnLabel ? "Active" : "active"
    }
    if (!m.projectStatus) {
        return returnLabel ? "Active" : "active"
    }
    if (!returnLabel) {
        return m.projectStatus?.toLowerCase()
    } else {
        return m.projectStatus.charAt(0).toUpperCase() + m.projectStatus.slice(1)
    }
}

export function getMissionStatusCls(mission) {
    return cx("x-sm-status-hl dna-pointer dna-hl", {
        accent: mission.projectStatus === "completed",
        orange: mission.projectStatus === "cancelled",
        mint: mission.projectStatus === "active" || !mission.projectStatus || mission.projectStatus === "open",
    })
}

/**
 * Gets an object id string
 * Takes under consideration populated references as well.
 * @param id {String|Object} The MongoDB ObjectID
 * @param [handlePopulatedRef] {Boolean} Whether to handle populated references or not
 * @return {*}
 */
export function getObjectId(id, handlePopulatedRef = true) {
    if (!id) {
        return id
    }

    if (typeof id === "string") {
        return id
    }

    if (id._id && handlePopulatedRef === true) {
        // Populated reference. Re-iterate once with child _id
        return getObjectId(id._id, false)
    }

    return id
}

export function ensureNumber(val) {
    let newVal = val
    if (newVal === null) return 0
    if (newVal === undefined) return 0
    if (typeof newVal === "string") {
        newVal = newVal.replaceAll(",", "")
    }
    if (Number.isNaN(+newVal)) return 0
    return +newVal
}

export function getRole(roleId, org, noRoleText) {
    if (!roleId) return { name: noRoleText || "Role not assigned", notFound: true }

    roleId = getObjectId(roleId)

    if (roleId && !org && typeof roleId === "string") {
        return { name: roleId }
    }

    const foundRole = org?.roles?.length ? org.roles.find((r) => roleId === r.id || roleId === r._id) : roleId

    if (foundRole) return foundRole

    if (!foundRole && roleId && typeof roleId === "string") return { name: roleId }

    return null
}

export function getPersonName(person, lastFirst) {
    let firstName = person?.ref?.firstName || person?.firstName
    let lastName = person?.ref?.lastName || person?.lastName

    if (!firstName && !lastName) {
        if (person?.invitePending) {
            return "Invite pending"
        }
        return "Not found"
    }

    if (lastFirst) {
        return lastName + ", " + firstName
    }

    return firstName + " " + lastName
}

export function getPersonLocation(person) {
    if (!person.ref) {
        return "Location not set yet"
    }
    if (!person.ref.city && !person.ref.state && !person.ref.country) {
        return "Location not set yet"
    }
    const value = person.ref.state + ", " + person.ref.country
    return value
}

export function listify(obj, mapFn) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc.push(mapFn(key, value))
        return acc
    }, [])
}

/**
 * Converts a metric value to milliseconds.
 * @param value {Number} The time value
 * @param metric {'sec'|'secs'|'second'|'seconds'|'min'|'mins'|'minute'|'minutes'|'hour'|'hours'} The metric of the provided value
 * @return {Number|undefined}
 */
export function toMillis(value, metric) {
    if (typeof value !== "number") {
        return
    }

    switch (metric) {
        case "sec":
        case "secs":
        case "second":
        case "seconds":
            return value * 1000
        case "min":
        case "mins":
        case "minute":
        case "minutes":
            return value * 60 * 1000
        case "hour":
        case "hours":
            return value * 60 * 60 * 1000
        default:
            return
    }
}

// Version 4.1
export function pSBC(percentage, color1, color2, useLinear) {
    let rOut
    let gOut
    let bOut
    let toColor
    let rgbaOutput
    const color2IsStr = typeof color2 == "string"

    if (typeof percentage != "number" || percentage < -1 || percentage > 1) return null
    if (typeof color1 != "string" || (color1[0] != "r" && color1[0] != "#") || (color2 && !color2IsStr)) return null

    rgbaOutput = color1.length > 9
    if (color2IsStr) {
        if (color2.length > 9) {
            rgbaOutput = true
        } else {
            if (color2 == "c") {
                rgbaOutput = !rgbaOutput
            } else {
                rgbaOutput = false
            }
        }
    }

    const fromColor = pSBC.pSBCr(color1)
    const isDarken = percentage < 0
    toColor = { r: 255, g: 255, b: 255, a: -1 }
    if (color2 && color2 != "c") {
        toColor = pSBC.pSBCr(color2)
    } else if (isDarken) {
        toColor = { r: 0, g: 0, b: 0, a: -1 }
    }
    if (isDarken) {
        percentage = -percentage
    }

    const inversePct = 1 - percentage

    if (!fromColor || !toColor) return null

    const linCalc = (from, to) => Math.round(inversePct * from + percentage * to)
    const logCalc = (from, to) => Math.round((inversePct * from ** 2 + percentage * to ** 2) ** 0.5)

    if (useLinear) {
        rOut = linCalc(fromColor.r, toColor.r)
        gOut = linCalc(fromColor.g, toColor.g)
        bOut = linCalc(fromColor.b, toColor.b)
    } else {
        rOut = logCalc(fromColor.r, toColor.r)
        gOut = logCalc(fromColor.g, toColor.g)
        bOut = logCalc(fromColor.b, toColor.b)
    }

    let fromAlpha = fromColor.a
    const toColorAlpha = toColor.a
    const hasAlpha = fromAlpha >= 0 || toColorAlpha >= 0
    if (hasAlpha) {
        if (fromAlpha < 0) {
            fromAlpha = toColorAlpha
        } else if (toColorAlpha >= 0) {
            fromAlpha = fromAlpha * inversePct + toColorAlpha * percentage
        }
    } else {
        fromAlpha = 0
    }

    if (rgbaOutput) {
        const aStr = hasAlpha ? "a" : ""
        const aNum = hasAlpha ? `,${Math.round(fromAlpha * 1000) / 1000}` : ""
        return `rgb${aStr}(${rOut},${gOut},${bOut}${aNum})`
    } else {
        const base = 4294967296
        const rPlace = rOut * 16777216
        const gPlace = gOut * 65536
        const bPlace = bOut * 256
        const aPlace = hasAlpha ? Math.round(fromAlpha * 255) : 0
        return "#" + (base + rPlace + gPlace + bPlace + aPlace).toString(16).slice(1, hasAlpha ? undefined : -2)
    }
}

/**
 * Input colors as rgb, rgba, or 3, 4, 6, or 8 digit hex. Rip to an object with r, g, b, a properties.
 * @param {string} d color string to rip
 * @return {{r: number, g: number, b: number, a: number}}
 */
pSBC.pSBCr = (d) => {
    const n = d.length
    const x = {}
    if (n > 9) {
        const parts = d.split(",")
        const [r, g, b, a] = parts
        if (parts.length < 3 || parts.length > 4) return null
        x.r = parseInt(r[3] == "a" ? r.slice(5) : r.slice(4))
        x.g = parseInt(g)
        x.b = parseInt(b)
        x.a = a ? parseFloat(a) : -1
        return x
    }
    if (n == 8 || n == 6 || n < 4) return null
    if (n < 6) {
        d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "")
    }
    d = parseInt(d.slice(1), 16)
    if (n == 9 || n == 5) {
        x.r = (d >> 24) & 255
        x.g = (d >> 16) & 255
        x.b = (d >> 8) & 255
        x.a = Math.round((d & 255) / 0.255) / 1000
    } else {
        x.r = d >> 16
        x.g = (d >> 8) & 255
        x.b = d & 255
        x.a = -1
    }
    return x
}

export function getCountryCodes() {
    return [
        "AD",
        "AE",
        "AF",
        "AG",
        "AI",
        "AL",
        "AM",
        "AO",
        "AQ",
        "AR",
        "AS",
        "AT",
        "AU",
        "AW",
        "AX",
        "AZ",
        "BA",
        "BB",
        "BD",
        "BE",
        "BF",
        "BG",
        "BH",
        "BI",
        "BJ",
        "BL",
        "BM",
        "BN",
        "BO",
        "BQ",
        "BR",
        "BS",
        "BT",
        "BV",
        "BW",
        "BY",
        "BZ",
        "CA",
        "CC",
        "CD",
        "CF",
        "CG",
        "CH",
        "CI",
        "CK",
        "CL",
        "CM",
        "CN",
        "CO",
        "CR",
        "CU",
        "CV",
        "CW",
        "CX",
        "CY",
        "CZ",
        "DE",
        "DJ",
        "DK",
        "DM",
        "DO",
        "DZ",
        "EC",
        "EE",
        "EG",
        "EH",
        "ER",
        "ES",
        "ET",
        "FI",
        "FJ",
        "FK",
        "FM",
        "FO",
        "FR",
        "GA",
        "GB",
        "GD",
        "GE",
        "GF",
        "GG",
        "GH",
        "GI",
        "GL",
        "GM",
        "GN",
        "GP",
        "GQ",
        "GR",
        "GS",
        "GT",
        "GU",
        "GW",
        "GY",
        "HK",
        "HM",
        "HN",
        "HR",
        "HT",
        "HU",
        "ID",
        "IE",
        "IL",
        "IM",
        "IN",
        "IO",
        "IQ",
        "IR",
        "IS",
        "IT",
        "JE",
        "JM",
        "JO",
        "JP",
        "KE",
        "KG",
        "KH",
        "KI",
        "KM",
        "KN",
        "KP",
        "KR",
        "KW",
        "KY",
        "KZ",
        "LA",
        "LB",
        "LC",
        "LI",
        "LK",
        "LR",
        "LS",
        "LT",
        "LU",
        "LV",
        "LY",
        "MA",
        "MC",
        "MD",
        "ME",
        "MF",
        "MG",
        "MH",
        "MK",
        "ML",
        "MM",
        "MN",
        "MO",
        "MP",
        "MQ",
        "MR",
        "MS",
        "MT",
        "MU",
        "MV",
        "MW",
        "MX",
        "MY",
        "MZ",
        "NA",
        "NC",
        "NE",
        "NF",
        "NG",
        "NI",
        "NL",
        "NO",
        "NP",
        "NR",
        "NU",
        "NZ",
        "OM",
        "PA",
        "PE",
        "PF",
        "PG",
        "PH",
        "PK",
        "PL",
        "PM",
        "PN",
        "PR",
        "PS",
        "PT",
        "PW",
        "PY",
        "QA",
        "RE",
        "RO",
        "RS",
        "RU",
        "RW",
        "SA",
        "SB",
        "SC",
        "SD",
        "SE",
        "SG",
        "SH",
        "SI",
        "SJ",
        "SK",
        "SL",
        "SM",
        "SN",
        "SO",
        "SR",
        "SS",
        "ST",
        "SV",
        "SX",
        "SY",
        "SZ",
        "TC",
        "TD",
        "TF",
        "TG",
        "TH",
        "TJ",
        "TK",
        "TL",
        "TM",
        "TN",
        "TO",
        "TR",
        "TT",
        "TV",
        "TW",
        "TZ",
        "UA",
        "UG",
        "UM",
        "US",
        "UY",
        "UZ",
        "VA",
        "VC",
        "VE",
        "VG",
        "VI",
        "VN",
        "VU",
        "WF",
        "WS",
        "XK",
        "YE",
        "YT",
        "ZA",
        "ZM",
        "ZW",
    ]
}

export function isLocalhost() {
    return Boolean(
        window.location.hostname === "localhost" ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === "[::1]" ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    )
}

/**
 * https://stackoverflow.com/a/48706852/1222409
 */
export const Cookie = {
    get: (name) => {
        let c = document.cookie.match(`(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`)[1]
        if (c) return decodeURIComponent(c)
    },
    set: (name, value, opts = {}) => {
        opts = Object.entries(opts).reduce((accumulatedStr, [k, v]) => `${accumulatedStr}; ${k}=${v}`, "")
        document.cookie = name + "=" + encodeURIComponent(value) + opts
    },
    delete: (name, opts) => Cookie.set(name, "", { "max-age": -1, ...opts }),
}
export const objectDiff = (obj1, obj2) => {
    const diff = {}

    // Check keys in obj1
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
                diff[key] = obj1[key]
            }
        }
    }

    // Check keys in obj2
    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
                diff[key] = obj2[key]
            }
        }
    }

    return diff
}

export const getNumberedRoles = (mission) => {
    const planItemsGroupedByRoleId = groupBy(
        mission.planItems.filter((p) => p.type === "person" && !p.wasDeleted),
        "role"
    )

    let planItems = []

    Object.keys(planItemsGroupedByRoleId).forEach((key, i) => {
        planItemsGroupedByRoleId[key].forEach((r, i) => {
            planItems.push({
                ...r,
                index: i + 1,
                numberOfRoles: planItemsGroupedByRoleId[key].length,
            })
        })
    })

    return planItems
}

export const darkThemes = ["main-theme", "peak-theme", "japan-theme"]
