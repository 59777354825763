import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

import Button from "../comp/Button"
import ButtonConfirm from "../comp/ButtonConfirm"

const Confirm = ({
    comp,
    onYes,
    onNo,
    noText = "No",
    noMint = false,
    onCancel,
    noCancel,
    open,
    yesColor,
    yesText = "Yes",
    width = 400,
    singleClick,
    severe,
    className,
}) => {
    return (
        <>
            <AnimatePresence>
                {open && (
                    <motion.div
                        className={"dna-dark dna-confirm-panel dna-center-col " + className}
                        initial={{ width: width, marginLeft: -width / 2, transform: "translateY(-100%)", opacity: 0 }}
                        animate={{ transform: "translateY(0%)", opacity: 1 }}
                        exit={{ transform: "translateY(-100%)", opacity: 0 }}
                        transition={{ type: "spring", stiffness: 100 }}
                    >
                        {!severe ? (
                            <img src="/img/astro-hold-on.png" style={{ height: 100, marginBottom: 20 }} />
                        ) : (
                            <img
                                src="/img/astro-warning.png"
                                style={{ height: 120, marginLeft: 20, marginBottom: 20 }}
                            />
                        )}

                        {comp}
                        <br />
                        <div>
                            {singleClick ? (
                                <>
                                    <Button mint={!yesColor} red={yesColor === "red"} onClick={onYes}>
                                        {yesText}
                                    </Button>
                                    &nbsp;
                                    {onNo && (
                                        <Button red={!noMint} mint={noMint} onClick={onNo}>
                                            {noText}
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <>
                                    <ButtonConfirm mint={!yesColor} red={yesColor === "red"} onClick={onYes}>
                                        {yesText}
                                    </ButtonConfirm>
                                    &nbsp;
                                    {onNo && (
                                        <ButtonConfirm red={!noMint} mint={noMint} onClick={onNo}>
                                            {noText}
                                        </ButtonConfirm>
                                    )}
                                </>
                            )}
                        </div>
                        <br />
                        {!noCancel && (
                            <Button clear onClick={onCancel}>
                                Cancel
                            </Button>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
            {open && (
                <div
                    className="dna-modal "
                    style={{ pointerEvents: "all", zIndex: 99999999 }}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                />
            )}
        </>
    )
}

export default Confirm
