import React from "react"

export default () => (
    <svg width="26px" height="50px" viewBox="0 0 31 50" className="dna-svg-icon dna-icon-board">

    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1362.000000, -143.000000)" className="fill-me">
            <g id="Group" transform="translate(1362.000000, 143.000000)">
                <path d="M1.0119959,9.39082999 L16.8100803,16.8370378 L29.9823093,8.01918319 L14.1842249,0.572975335 L1.0119959,9.39082999 Z" ></path>
                <path d="M1.0119959,22.39083 L16.8100803,29.8370378 L29.9823093,21.0191832 L14.1842249,13.5729753 L1.0119959,22.39083 Z"  opacity="0.148494112"></path>
                <path d="M1.0119959,16.39083 L16.8100803,23.8370378 L29.9823093,15.0191832 L14.1842249,7.57297534 L1.0119959,16.39083 Z" opacity="0.522984601"></path>
            </g>
        </g>
    </g>

    </svg>
)
