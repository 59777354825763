import React from "react"
import cx from "classnames"

export default class CheckBoxGroup extends React.PureComponent {
    static defaultProps = {
        data: [],
    }

    constructor(props) {
        super(props)

        this.state = {
            checkBoxesArray: this.props.data,
            itemChecked: null,
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.controlled) {
            this.setState({
                checkBoxesArray: nextProps.data,
            })
        }
    }
    onChangeFired(index, name, value, isChecked) {
        let newArray = this.state.checkBoxesArray.slice()

        if (this.props.radio) {
            newArray.forEach((button) => {
                button.checked = false
            })
        }

        newArray[index].checked = isChecked

        if (this.props.controlled) {
            if (this.props.onChange) this.props.onChange(newArray, index, isChecked, newArray[index])
        } else {
            this.setState(
                {
                    checkBoxesArray: newArray,
                },
                () => {
                    if (this.props.onChange)
                        this.props.onChange(this.state.checkBoxesArray, index, isChecked, newArray[index])
                }
            )
        }
    }

    render() {
        const cn = cx("dna-check-boxes", this.props.className, {
            "dna-horizontal-checkboxes": this.props.horizontal,
        })

        let displayMap = this.state.checkBoxesArray.map((obj, i) => {
            return (
                <CheckBox
                    key={obj._id || obj.id || i}
                    controlled={true}
                    onChange={this.onChangeFired.bind(this, i)}
                    name={this.props.name}
                    required={this.props.required}
                    disabled={obj.disabled || this.props.disabled}
                    {...obj}
                />
            )
        })

        return <div className={cn}>{displayMap}</div>
    }
}

export class CheckBox extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            checked: this.props.checked,
        }
    }
    onClick(e) {
        const { required, disabled, controlled } = this.props
        e.persist()
        const ev = e

        if (disabled) {
            if (this.props.disabledCb) this.props.disabledCb()
            return
        }

        if (!controlled) {
            ev.stopPropagation()

            this.setState(
                {
                    checked: required ? true : !this.state.checked,
                },
                () => {
                    if (this.props.onChange)
                        this.props.onChange(this.props.name, this.props.value, this.state.checked, this.props, ev)
                }
            )
        } else {
            if (this.props.onChange) this.props.onChange(this.props.name, this.props.value, !this.state.checked, ev)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.controlled && nextProps.checked !== this.state.checked) {
            this.setState({
                checked: nextProps.checked,
            })
        }
    }

    render() {
        const cn = cx("dna-checkbox", this.props.className, {
            active: this.props.controlled ? this.props.checked : this.state.checked,
            "dna-light-text": this.props.disabled || this.props.disabled,
        })

        const { label } = this.props

        return (
            <span id={this.props.id} data-checked={this.state.checked} className={cn}>
                <svg
                    className={this.props.disabled ? "dna-disabled" : undefined}
                    onClick={(e) => {
                        e.stopPropagation()
                        this.onClick(e)
                    }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <g>
                        {/*OFF*/}
                        <path d="M12 2.5a9.5 9.5 0 0 1 9.5 9.5 9.5 9.5 0 0 1-9.5 9.5A9.5 9.5 0 0 1 2.5 12 9.5 9.5 0 0 1 12 2.5z" />
                        {/*ON*/}
                        <path d="M12,3 C7.032,3 3,7.032 3,12 C3,16.968 7.032,21 12,21 C16.968,21 21,16.968 21,12 C21,7.032 16.968,3 12,3 L12,3 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L16.59,7.58 L18,9 L10,17 Z" />
                    </g>
                </svg>
                <span className="cb-content">{label}</span>
            </span>
        )
    }
}
