import React from "react"
import flatMap from "lodash/flatMap"
import Tip from "./Tip"
import { PiPushPinDuotone, PiEyeDuotone } from "react-icons/pi"
import * as DOMPurify from "dompurify"
import "./TaskTabs.scss"

const TaskTabs = ({ app, isDark }) => {
    let allActions = flatMap(app.state.missions, "planItems").reduce((cum, p) => [...cum, ...(p.actions || [])], [])
    const allActionItems = flatMap(allActions, "actionItems")
    const pinned = allActionItems.filter((ai) => ai.pinned?.find((p) => p.by === app.state.person._id))
    const recent = allActionItems
        .reduce((acc, ai) => {
            const seenItem = ai.seen?.find((s) => s.by === app.state.person._id)
            if (seenItem) {
                acc.push({ ...ai, when: seenItem.when })
            }
            return acc
        }, [])
        .sort((b, a) => a.when - b.when)

    if (!recent.length) {
        return <></>
    }

    return (
        <div className={isDark ? "x-task-tabs dna-dark" : "x-task-tabs"}>
            <div className="x-task-recent pinned">
                {pinned.slice(0, 6).map((ai) => {
                    return (
                        <Tip
                            html={
                                <div className="tip-left">
                                    <p>
                                        <b>
                                            <PiPushPinDuotone
                                                fontSize={16}
                                                color="pink"
                                                style={{ transform: "translateY(2px)" }}
                                            />{" "}
                                            {ai.title || "Task with no title..."}
                                        </b>
                                    </p>

                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(ai.shortDescription),
                                        }}
                                    />

                                    <div className="dna-light-text dna-smaller-text" style={{ marginTop: 5 }}>
                                        PINNED TASK: {app.state.missions.find((m) => m._id === ai.missionId)?.title}
                                    </div>
                                </div>
                            }
                            onClick={() => {
                                app.showActionItemDetails(ai, { noSeen: true })
                            }}
                        >
                            {ai.title}
                        </Tip>
                    )
                })}
            </div>

            <div className="x-task-recent">
                {recent.slice(0, 6).map((ai) => {
                    return (
                        <Tip
                            html={
                                <div className="tip-left">
                                    <p>
                                        <b>
                                            <PiEyeDuotone
                                                fontSize={16}
                                                color="#9effdf"
                                                style={{ transform: "translateY(2px)" }}
                                            />{" "}
                                            {ai.title || "Task with no title..."}
                                        </b>
                                    </p>

                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(ai.shortDescription),
                                        }}
                                    />

                                    <div className="dna-light-text dna-smaller-text" style={{ marginTop: 5 }}>
                                        RECENTLY VIEWED: {app.state.missions.find((m) => m._id === ai.missionId)?.title}
                                    </div>
                                </div>
                            }
                            onClick={() => {
                                app.showActionItemDetails(ai, { noSeen: true })
                            }}
                        >
                            {ai.title}
                        </Tip>
                    )
                })}
            </div>
        </div>
    )
}

export default TaskTabs
